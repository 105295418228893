import {
  ConfirmCloseDialog,
  PuiAlert,
  SignatureDialog,
} from '@pbt/pbt-ui-components'

import AddToAppointmentDialog from '~/components/common/addToAppointment/AddToAppointmentDialog'
import AttachmentLightbox from '~/components/common/attachments/AttachmentLightbox'
import AutomaticCommunicationPreviewDialog from '~/components/common/automaticCommunication/AutomaticCommunicationPreviewDialog'
import ChooseAvatarDialog from '~/components/common/dialog/ChooseAvatarDialog'
import ClientSearchDialog from '~/components/common/dialog/ClientSearchDialog'
import EmailAttachment from '~/components/common/dialog/EmailAttachmentDialog'
import ChargeSheetLabOrderManagementDialog from '~/components/common/dialog/labTests/ChargeSheetLabOrderManagementDialog'
import LabOrderAssignmentDialog from '~/components/common/dialog/labTests/LabOrderAssignmentDialog'
import LabOrderManagementDialog from '~/components/common/dialog/labTests/LabOrderManagementDialog'
import LabTestPriceDialog from '~/components/common/dialog/labTests/LabTestPriceDialog'
import LostAccessToClinicAlertDialog from '~/components/common/dialog/LostAccessToClinicAlertDialog'
import MissingResourceDialog from '~/components/common/dialog/MissingResourceDialog'
import ReportUnavailableDialog from '~/components/common/dialog/ReportUnavailableDialog'
import TeamMemberAssignmentDialog from '~/components/common/dialog/TeamMemberAssignmentDialog'
import TypographyDialog from '~/components/common/dialog/TypographyDialog'
import UploadImageDialog from '~/components/common/dialog/UploadImageDialog'
import SelectContactDialog from '~/components/common/email/SelectContactDialog'
import EmailPreviewDialog from '~/components/common/emailPreview/EmailPreviewDialog'
import DymoErrorDialog from '~/components/common/labelPrinting/DymoErrorDialog'
import ExpanderConfirmCloseDialog from '~/components/common/lists/ExpanderConfirmCloseDialog'
import ValidationErrorDialog from '~/components/common/validation/ValidationErrorDialog'
import BulkPricesCloseDialog from '~/components/dashboard/admin/catalog/bulk-prices/BulkPricesCloseDialog'
import BundleCustomPriceDialog from '~/components/dashboard/admin/catalog/bundles/BundleCustomPriceDialog'
import BundleDialog from '~/components/dashboard/admin/catalog/bundles/BundleDialog'
import BundleTaskDialog from '~/components/dashboard/admin/catalog/bundles/BundleTaskDialog'
import DoctorSignatureDialog from '~/components/dashboard/admin/catalog/documents/DoctorSignatureDialog'
import DocumentDialog from '~/components/dashboard/admin/catalog/documents/DocumentDialog'
import AdjustmentDialog from '~/components/dashboard/admin/catalog/inventory/adjustments/AdjustmentDialog'
import BulkAdjustmentDialog from '~/components/dashboard/admin/catalog/inventory/adjustments/bulk-create-adjustments/BulkAdjustmentDialog'
import InventoryDialog from '~/components/dashboard/admin/catalog/inventory/catalog/InventoryDialog'
import VariationDialog from '~/components/dashboard/admin/catalog/inventory/catalog/VariationDialog'
import GlobalVariationSelectDialog from '~/components/dashboard/admin/catalog/inventory/global-selector/GlobalVariationSelectDialog'
import OnHandCatalogPrintDialog from '~/components/dashboard/admin/catalog/inventory/on-hand/OnHandCatalogPrintDialog'
import AddShipmentItemDialog from '~/components/dashboard/admin/catalog/inventory/shipments/AddShipmentItemDialog'
import ShipmentDialog from '~/components/dashboard/admin/catalog/inventory/shipments/ShipmentDialog'
import ShipmentItemDialog from '~/components/dashboard/admin/catalog/inventory/shipments/ShipmentItemDialog'
import PriceDialog from '~/components/dashboard/admin/catalog/procedures/PriceDialog'
import QuestionDialog from '~/components/dashboard/admin/catalog/questions/QuestionDialog'
import NewReminderProtocolGroupDialog from '~/components/dashboard/admin/catalog/reminder-protocol-groups/NewReminderProtocolGroupDialog'
import ReminderProtocolDialog from '~/components/dashboard/admin/catalog/reminder-protocol-groups/ReminderProtocolDialog'
import { AppointmentConfigurationConfirmationDialog } from '~/components/dashboard/admin/general/appointment-configuration/AppointmentConfigurationConfirmationDialog'
import BenchmarkingActivationKeyDialog from '~/components/dashboard/admin/general/benchmarking/NewBenchmarkingActivationDialog'
import ContactDialog from '~/components/dashboard/admin/general/contacts/ContactDialog'
import EmployeeIdDialog from '~/components/dashboard/admin/general/members/EmployeeIdDialog'
import LicenseDialog from '~/components/dashboard/admin/general/members/LicenseDialog'
import RemoveMemberDialog from '~/components/dashboard/admin/general/members/RemoveMemberDialog'
import RolesDialog from '~/components/dashboard/admin/general/members/RolesDialog'
import TeamMemberDialog from '~/components/dashboard/admin/general/members/TeamMemberDialog'
import AddBusinessChildDialog from '~/components/dashboard/admin/general/practices/AddBusinessChildDialog'
import CreatePracticeDialog from '~/components/dashboard/admin/general/practices/CreatePracticeDialog'
import CreatePracticeGroupDialog from '~/components/dashboard/admin/general/practices/CreatePracticeGroupDialog'
import AutomaticCommunicationDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/appointment-communications/AutomaticCommunicationDialog'
import BoopSlaMessagesPreviewDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/boop-communications/preview/BoopSlaMessagesPreviewDialog'
import KioskPreviewDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/kiosk-configuration/KioskPreviewDialog'
import MigrationActivationTokenDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/continuousMigration/MigrationActivationTokenDialog'
import ReviewExceptionsDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/continuousMigration/ReviewExceptionsDialog'
import CoreMigrationDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/migration/core-migration/CoreMigrationDialog'
import CovetrusIntegrationDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/migration/core-migration/CovetrusIntegrationDialog'
import ImportAlreadyDoneDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/migration/core-migration/ImportAlreadyDoneDialog'
import OnGoingStandardizationDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/migration/core-migration/OnGoingStandardizationDialog'
import SelectPimsDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/migration/core-migration/SelectPimsDialog'
import UploadAdditionalFilesDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/migration/core-migration/UploadAdditionalFilesDialog'
import UploadSessionFilesDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/migration/core-migration/UploadSessionFilesDialog'
import MigrationV2Dialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/migrationV2/core-migration/MigrationV2Dialog'
import PracticeCloningExportDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/practiceCloning/PracticeCloningExportDialog'
import PracticeCloningImportDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/practiceCloning/PracticeCloningImportDialog'
import PreviewReminderTemplateDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/reminderCommunication/PreviewReminderTemplateDialog'
import ReminderCommunicationDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/reminderCommunication/ReminderCommunicationDialog'
import RhapsodyPaySetupDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/rhapsodyPay/RhapsodyPaySetupDialog'
import PreviewWellnessPromotionDialog from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/wellness-plans/PreviewWellnessPromotionDialog'
import SpaceDialog from '~/components/dashboard/admin/general/spaces/SpaceDialog'
import AvailabilityRuleDialog from '~/components/dashboard/admin/scheduling/availability/AvailabilityRuleDialog'
import AddReminderProtocolItemsDialog from '~/components/dashboard/catalog/AddReminderProtocolItemsDialog'
import AddWellnessPlanCoverageItemsDialog from '~/components/dashboard/catalog/AddWellnessPlanCoverageItemsDialog'
import AddChargeSheetDialog from '~/components/dashboard/charge-sheet/AddChargeSheetItemDialog'
import { DeleteLineItemDialog } from '~/components/dashboard/charge-sheet/DeleteLineItemDialog'
import EmailChargeSheetDialog from '~/components/dashboard/charge-sheet/email/EmailChargeSheetDialog'
import NotFinalizedOrderDialog from '~/components/dashboard/charge-sheet/NotFinalizedOrderDialog'
import PrintChargeSheet from '~/components/dashboard/charge-sheet/print/PrintChargeSheet'
import ChangePetParentDialog from '~/components/dashboard/clients/change-patient-parent/ChangePetParentDialog'
import ClientDialog from '~/components/dashboard/clients/ClientDialog'
import ClientDuplicatesDialog from '~/components/dashboard/clients/ClientDuplicatesDialog'
import ClientDetailsEditDialog from '~/components/dashboard/clients/details/ClientDetailsEditDialog'
import ClientPreferencesDialog from '~/components/dashboard/clients/details/ClientPreferencesDialog'
import PatientsListDialog from '~/components/dashboard/clients/details/new-client-and-patient/PatientsListDialog'
import AddAlertsDialog from '~/components/dashboard/clients/patient/AddAlertsDialog'
import AutomatedPatientUpdateAlertDialog from '~/components/dashboard/clients/patient/AutomatedPatientUpdateAlertDialog'
import PatientDialog from '~/components/dashboard/clients/patient/PatientDialog'
import PatientPreferencesDialog from '~/components/dashboard/clients/patient/PatientPreferencesDialog'
import CoPetParentDialog from '~/components/dashboard/clients/pet-friend/CoPetParentDialog'
import EmergencyContactDialog from '~/components/dashboard/clients/pet-friend/EmergencyContactDialog'
import PetFriendDialog from '~/components/dashboard/clients/pet-friend/PetFriendDialog'
import ConversationAttachmentDialog from '~/components/dashboard/communications/common/ConversationAttachmentDialog'
import ConversationMultiAttachmentDialog from '~/components/dashboard/communications/common/ConversationMultiAttachmentDialog'
import CreatedConversationsInfoDialog from '~/components/dashboard/communications/common/CreatedConversationsInfoDialog'
import SaveAsClientEmailDialog from '~/components/dashboard/communications/common/SaveAsClientEmailDialog'
import ConversationDeleteDialog from '~/components/dashboard/communications/conversations-chat/ConversationDeleteDialog'
import ConversationInternalNoteDialog from '~/components/dashboard/communications/conversations-chat/ConversationInternalNoteDialog'
import AddClientPatientToConversationDialog from '~/components/dashboard/communications/conversations-chat/right-rail/AddClientPatientToConversationDialog'
import SendBoopLinkDialog from '~/components/dashboard/communications/emailing/SendBoopLinkDialog'
import SendHybridMembershipInviteDialog from '~/components/dashboard/communications/emailing/SendHybridMembershipInviteDialog'
import NewConversationWithStepsDialog from '~/components/dashboard/communications/new-conversation-dialog/NewConversationWithStepsDialog'
import MedicalHistoryCardDialog from '~/components/dashboard/header/top-menu/MedicalHistoryCardDialog'
import ImagingAssignmentDialog from '~/components/dashboard/imaging-dashboard/ImagingAssignmentDialog'
import BatchImagingOrderManagementDialog from '~/components/dashboard/imaging-procedures/BatchImagingOrderManagementDialog'
import BatchImagingOrderManagementDialogV2 from '~/components/dashboard/imaging-procedures/BatchImagingOrderManagementDialogV2'
import ImagingOrderDialog from '~/components/dashboard/imaging-procedures/ImagingOrderDialog'
import AddInvoiceItemDialog from '~/components/dashboard/invoices/AddInvoiceItemDialog'
import AppointmentsListDialog from '~/components/dashboard/invoices/AppointmentsListDialog'
import BatchInvoiceDialog from '~/components/dashboard/invoices/batch/BatchInvoiceDialog'
import EmailInvoiceDialog from '~/components/dashboard/invoices/email/EmailinvoiceDialog'
import EstimateApprovalDialog from '~/components/dashboard/invoices/EstimateApprovalDialog'
import EstimatesListDialog from '~/components/dashboard/invoices/EstimatesListDialog'
import InvoiceDialog from '~/components/dashboard/invoices/InvoiceDialog'
import InvoiceMissingFieldsDialog from '~/components/dashboard/invoices/locale-invoice-options/spain/InvoiceMissingFieldsDialog'
import AddPaymentDialog from '~/components/dashboard/invoices/payment/AddPaymentDialog'
import ApplyPaymentToInvoiceDialog from '~/components/dashboard/invoices/payment/ApplyPaymentToInvoiceDialog'
import { ChewyPaymentDialog } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyPaymentDialog/ChewyPaymentDialog'
import PaymentDetailsDialog from '~/components/dashboard/invoices/payment/payment-details-dialog/PaymentDetailsDialog'
import PaymentRequestDialog from '~/components/dashboard/invoices/payment/PaymentRequestDialog'
import RefundPaymentDialog from '~/components/dashboard/invoices/payment/RefundPaymentDialog'
import UndoPaymentDialog from '~/components/dashboard/invoices/payment/UndoPaymentDialog'
import PrintInvoice from '~/components/dashboard/invoices/PrintInvoice'
import RedeemLoyaltyPointsDialog from '~/components/dashboard/invoices/RedeemLoyaltyPointsDialog'
import RequiredDepositDialog from '~/components/dashboard/invoices/RequiredDepositDialog'
import SaveInvoiceAlert from '~/components/dashboard/invoices/SaveInvoiceAlert'
import LabResultAssignmentDialog from '~/components/dashboard/lab-tests-dashboard/LabResultAssignmentDialog'
import PrintCageLabelDialog from '~/components/dashboard/labels/PrintCageLabelDialog'
import PrintFolderLabelDialog from '~/components/dashboard/labels/PrintFolderLabelDialog'
import PrintInHouseOrderLabelDialog from '~/components/dashboard/labels/PrintInHouseOrderLabelDialog'
import PrintLabOrderLabelDialog from '~/components/dashboard/labels/PrintLabOrderLabelDialog'
import PrintPracticeAddressLabelDialog from '~/components/dashboard/labels/PrintPracticeAddressLabelDialog'
import AttachmentHistoryDialog from '~/components/dashboard/medical-history/attachment/AttachmentHistoryDialog'
import MultiAttachmentHistoryDialog from '~/components/dashboard/medical-history/attachment/MultiAttachmentHistoryDialog'
import DiagnosisHistoryDialog from '~/components/dashboard/medical-history/diagnosis/DiagnosisHistoryDialog'
import NoteHistoryDialog from '~/components/dashboard/medical-history/note/NoteHistoryDialog'
import VaccineHistoryDialog from '~/components/dashboard/medical-history/vaccines/VaccineHistoryDialog'
import CancelMembershipDetailsDialog from '~/components/dashboard/membership/cancel/CancelMembershipDetailsDialog'
import CancelMembershipDialog from '~/components/dashboard/membership/cancel/CancelMembershipDialog'
import MembershipPaymentDetailsDialog from '~/components/dashboard/membership/MembershipPaymentDetailsDialog'
import MembershipSignUpDialog from '~/components/dashboard/membership/MembershipSignUpDialog'
import PatientMembershipDialog from '~/components/dashboard/membership/PatientMembershipDialog'
import PatientMembershipPaymentInfoDialog from '~/components/dashboard/membership/PatientMembershipPaymentInfoDialog'
import NotificationStylesPreviewDialog from '~/components/dashboard/notifications/NotificationStylesPreviewDialog'
import DoctorLicenseDialog from '~/components/dashboard/prescription/prescription-dialogs/DoctorLicenseDialog'
import DrugPrescriptionDialog from '~/components/dashboard/prescription/prescription-dialogs/DrugPrescriptionDialog'
import DrugRefillDialog from '~/components/dashboard/prescription/prescription-dialogs/DrugRefillDialog'
import FoodPrescriptionDialog from '~/components/dashboard/prescription/prescription-dialogs/FoodPrescriptionDialog'
import FoodRefillDialog from '~/components/dashboard/prescription/prescription-dialogs/FoodRefillDialog'
import PrescriptionDeclineReasonDialog from '~/components/dashboard/prescription/prescription-dialogs/PrescriptionDeclineReasonDialog'
import PrescriptionMissingFieldsDialog from '~/components/dashboard/prescription/prescription-dialogs/PrescriptionMissingFieldsDialog'
import ProfileDialog from '~/components/dashboard/profile/ProfileDialog'
import SwitchUserDialog from '~/components/dashboard/profile/SwitchUserDialog'
import AddReminderDialog from '~/components/dashboard/reminders/add-reminder/AddReminderDialog'
import ReminderDetailsDialog from '~/components/dashboard/reminders/ReminderDetailsDialog'
import RemindersListDialog from '~/components/dashboard/reminders/RemindersListDialog'
import ChooseZoomUserDialog from '~/components/dashboard/soap/appointment/ChooseZoomUserDialog'
import SelectAttachmentDialog from '~/components/dashboard/soap/appointment/SelectAttachmentDialog'
import SoapAttachmentDialog from '~/components/dashboard/soap/appointment/SoapAttachmentDialog'
import SoapMultiAttachmentDialog from '~/components/dashboard/soap/appointment/SoapMultiAttachmentDialog'
import VitalsConfirmationDialog from '~/components/dashboard/soap/appointment/VitalsConfirmationDialog'
import AppointmentReportCardDialog from '~/components/dashboard/soap/AppointmentReportCardDialog'
import DeclineFinalizedLabTestDialog from '~/components/dashboard/soap/DeclineFinalizedLabTestDialog'
import DeclineLabOrderDialog from '~/components/dashboard/soap/DeclineLabOrderDialog'
import EmailPrescriptionDialog from '~/components/dashboard/soap/EmailPrescriptionDialog'
import SoapExamFindingsValidationErrorDialog from '~/components/dashboard/soap/examination/SoapExamFindingsValidationErrorDialog'
import NotesDialog from '~/components/dashboard/soap/NotesDialog'
import ChooseShipmentItemDialog from '~/components/dashboard/soap/order/vaccine/ChooseShipmentItemDialog'
import VaccineDetailsDialog from '~/components/dashboard/soap/order/vaccine/VaccineDetailsDialog'
import PatientMissingFieldsDialog from '~/components/dashboard/soap/PatientMissingFieldsDialog'
import PetParentChangedDialog from '~/components/dashboard/soap/PetParentChangedDialog'
import RemoveFinalizedLabTestDialog from '~/components/dashboard/soap/RemoveFinalizedLabTestDialog'
import RemoveLabOrderDialog from '~/components/dashboard/soap/RemoveLabOrderDialog'
import SoapAutochargedAlert from '~/components/dashboard/soap/SoapAutochargedAlert'
import SoapFinalizedAlert from '~/components/dashboard/soap/SoapFinalizedAlert'
import RemoveOrdersFromPostedInvoiceDialog from '~/components/dashboard/soap/to-do/RemoveOrdersFromPostedInvoiceDialog'
import ProofOfVaccinationDialog from '~/components/dashboard/soap/vaccinations/ProofOfVaccinationDialog'
import RabiesVaccinationsDialog from '~/components/dashboard/soap/vaccinations/RabiesVaccinationsDialog'
import VaccineCertificatePreviewDialog from '~/components/dashboard/soap/vaccinations/VaccineCertificatePreviewDialog'
import OTCValidationDialog from '~/components/dashboard/soap/validation/OTCValidationDialog'
import SoapValidationDialog from '~/components/dashboard/soap/validation/SoapValidationDialog'
import VaccineCertificateValidationDialog from '~/components/dashboard/soap/validation/VaccineCertificateValidationDialog'
import VitalsDialog from '~/components/dashboard/soap/vitals/VitalsDialog'
import AddendumDialog from '~/components/dashboard/soap/wrap-up/addendum/AddendumDialog'
import ClientSurveyDialog from '~/components/dashboard/soap/wrap-up/ClientSurveyDialog'
import AddNewLabTestDialog from '~/components/dashboard/soap/wrap-up/lab-orders/AddNewLabTestDialog'
import EmailOrderResultDialog from '~/components/dashboard/soap/wrap-up/lab-orders/EmailOrderResultDialog'
import FinalizeLabOrderDialog from '~/components/dashboard/soap/wrap-up/lab-orders/FinalizeLabOrderDialog'
import LabTestPriceValidationDialog from '~/components/dashboard/soap/wrap-up/lab-orders/order-management-dialog/LabTestPriceValidationDialog'
import LabVendorProvidersDialog from '~/components/dashboard/soap/wrap-up/lab-orders/order-management-dialog/LabVendorProvidersDialog'
import OrderResultsDialog from '~/components/dashboard/soap/wrap-up/lab-orders/OrderResultsDialog'
import OrderResultFrameDialog from '~/components/dashboard/soap/wrap-up/lab-orders/OrderResultsFrameDialog'
import SoapNotesValidationErrorDialog from '~/components/dashboard/soap/wrap-up/SoapNotesValidationErrorDialog'
import SoapWrapUpLeaveDialog from '~/components/dashboard/soap/wrap-up/SoapWrapUpLeaveDialog'
import SoapOrderValidationErrorDialog from '~/components/dashboard/soapV2/alerts/SoapOrderValidationErrorAlertDialog'
import { FinalizeChewyActiveRxDialog } from '~/components/dashboard/soapV2/finalize-orders/chewy-active-rx/FinalizeChewyActiveRxDialog'
import AssignTasksDialog from '~/components/dashboard/tasks-dashboard/AssignTasksDialog'
import MultipleTasksDialog from '~/components/dashboard/tasks-dashboard/MultipleTasksDialog'
import RepeatTasksDialog from '~/components/dashboard/tasks-dashboard/RepeatTasksDialog'
import AddClientPatientToRxRequestDialog from '~/components/dashboard/tasks-dashboard/rx-request-task/AddClientPatientToRxRequestDialog'
import TaskDialog from '~/components/dashboard/tasks-dashboard/TaskDialog'
import TimeTrackerDialog from '~/components/dashboard/time-tracking/TimeTrackerDialog'
import TimeTrackerEditDialog from '~/components/dashboard/time-tracking/TimeTrackerEditDialog'
import AdjustTimetableColumnsDialog from '~/components/dashboard/timetable/header/AdjustTimetableColumnsDialog'
import KioskDialog from '~/components/dashboard/timetable/rail/KioskDialog'
import AppointmentDialog from '~/components/dashboard/timetable/scheduler/appointment/AppointmentDialog'
import AppointmentNewClientConfirmationDialog from '~/components/dashboard/timetable/scheduler/appointment/AppointmentNewClientConfirmationDialog'
import EmailAppointmentConfirmationDialog from '~/components/dashboard/timetable/scheduler/appointment/EmailAppointmentConfirmationDialog'
import EmailAppointmentDialog from '~/components/dashboard/timetable/scheduler/appointment/EmailAppointmentDialog'
import RecurringEventDialog from '~/components/dashboard/timetable/scheduler/appointment/recurrence/RecurringEventDialog'
import WellnessPlanExtraDialog from '~/components/dashboard/wellness-plans/steps/plan-basics/WellnessPlanExtraDialog'
import WellnessPlanPackageDialog from '~/components/dashboard/wellness-plans/steps/plan-basics/WellnessPlanPackageDialog'
import WellnessPlanTierDialog from '~/components/dashboard/wellness-plans/steps/plan-basics/WellnessPlanTierDialog'
import SelectBenefitAppointmentTypeDialog from '~/components/dashboard/wellness-plans/steps/plan-customization/SelectBenefitAppointmentTypeDialog'
import WellnessPlanBenefitsDialog from '~/components/dashboard/wellness-plans/steps/plan-customization/WellnessPlanBenefitsDialog'
import WellnessPlanSavingsDialog from '~/components/dashboard/wellness-plans/WellnessPlanSavingsDialog'
import DialogNames from '~/constants/DialogNames'

import { CancelFutureAppointmentsAndMembershipDialog } from '../common/dialog/CancelFutureAppointmentsAndMembershipDialog'
import SpaceSelectDialog from '../common/dialog/SpaceSelectDialog'
import MultiValidationErrorDialog from '../common/validation/MultiValidationErrorDialog'
import { AddDateTimePickerDialog } from '../dashboard/admin/catalog/documents/AddDateTimePickerDialog'
import { AddSingleCheckboxDialog } from '../dashboard/admin/catalog/documents/AddSingleCheckboxDialog'
import { DocumentFormPreviewDialog } from '../dashboard/admin/catalog/documents/DocumentFormPreviewDialog'
import { DocumentRadioGroupDialog } from '../dashboard/admin/catalog/documents/DocumentRadioGroupDialog'
import { MultipleSelectDialog } from '../dashboard/admin/catalog/documents/MultipleSelectDialog'
import { TextEntryDialog } from '../dashboard/admin/catalog/documents/TextEntryDialog'
import { AutoReplyMessagePreviewDialog } from '../dashboard/admin/general/practices/PracticeDetailsSections/auto-reply-communications/AutoReplyMessagePreviewDialog'
import { GeneralInformationLocationDialog } from '../dashboard/admin/general/practices/PracticeDetailsSections/general-information/GeneralInformationLocationDialog'
import SoapTemplateTabDialog from '../dashboard/admin/general/soap-customization/dialog/SoapTemplateTabDialog'
import EmailPostedInvoiceDialog from '../dashboard/charge-sheet/invoice/EmailPostedInvoiceDialog'
import InvoiceDiscountReasonDialog from '../dashboard/charge-sheet/InvoiceDiscountReasonDialog'
import PrintPostedInvoice from '../dashboard/charge-sheet/print/PrintPostedInvoice'
import { AddClientPaymentDialog } from '../dashboard/clients/balance/dialogs/add-client-payment-dialog/AddClientPaymentDialog'
import { CreditAdjustmentDialog } from '../dashboard/invoices/payment/credit-adjustment/CreditAdjustmentDialog'
import LabTestAttachmentDialog from '../dashboard/lab-tests-dashboard/lab-tests-table/LabTestAttachmentDialog'
import LinkChewyAccountDialog from '../dashboard/link-chewy-account/LinkChewyAccountDialog'
import LinkIndividualPatientDialog from '../dashboard/link-chewy-account/LinkIndividualPatientDialog'
import { MatchPetPatientDialog } from '../dashboard/link-chewy-account/MatchPetPatientDialog'
import { PatientMembershipPaymentLinkDialog } from '../dashboard/membership/PatientMembershipPaymentLinkDialog'
import { AppointmentCancellationReasonDialog } from '../dashboard/timetable/scheduler/appointment/AppointmentCancellationReasonDialog'
import TermsAndConditionsDialog from '../terms-and-conditions/TermsAndConditionsDialog'

const DialogComponentMap = {
  [DialogNames.ADD_ALERTS_DIALOG]: AddAlertsDialog,
  [DialogNames.ADD_BUSINESS_CHILD]: AddBusinessChildDialog,
  [DialogNames.ADD_CHARGE_SHEET_ITEM]: AddChargeSheetDialog,
  [DialogNames.ADD_CLIENT_PATIENT_TO_CONVERSATION]:
    AddClientPatientToConversationDialog,
  [DialogNames.ADD_CLIENT_PATIENT_TO_RX_REQUEST]:
    AddClientPatientToRxRequestDialog,
  [DialogNames.ADD_CLIENT_PAYMENT]: AddClientPaymentDialog,
  [DialogNames.ADD_INVOICE_ITEM]: AddInvoiceItemDialog,
  [DialogNames.ADD_NEW_LAB_TEST]: AddNewLabTestDialog,
  [DialogNames.ADD_PAYMENT]: AddPaymentDialog,
  [DialogNames.ADD_REMINDER_PROTOCOL_ITEMS]: AddReminderProtocolItemsDialog,
  [DialogNames.ADD_REMINDER]: AddReminderDialog,
  [DialogNames.ADD_SHIPMENT_ITEM]: AddShipmentItemDialog,
  [DialogNames.ADD_SINGLE_CHECKBOX]: AddSingleCheckboxDialog,
  [DialogNames.ADD_DATE_TIME_PICKER]: AddDateTimePickerDialog,
  [DialogNames.ADD_TO_APPOINTMENT]: AddToAppointmentDialog,
  [DialogNames.ADD_WELLNESS_PLAN_COVERAGE_ITEMS]:
    AddWellnessPlanCoverageItemsDialog,
  [DialogNames.ADDENDUM]: AddendumDialog,
  [DialogNames.ADJUST_TIMETABLE_COLUMNS]: AdjustTimetableColumnsDialog,
  [DialogNames.ADJUSTMENT_DIALOG]: AdjustmentDialog,
  [DialogNames.ADVANCED_CLIENTS_SEARCH]: ClientSearchDialog,
  [DialogNames.APPLY_PAYMENT_TO_INVOICE]: ApplyPaymentToInvoiceDialog,
  [DialogNames.APPOINTMENT_CANCELLATION_REASON]:
    AppointmentCancellationReasonDialog,
  [DialogNames.APPOINTMENT_CONFIGURATION_CONFIRMATION]:
    AppointmentConfigurationConfirmationDialog,
  [DialogNames.APPOINTMENT_NEW_CLIENT_CONFIRMATION]:
    AppointmentNewClientConfirmationDialog,
  [DialogNames.APPOINTMENT_REPORT_CARD]: AppointmentReportCardDialog,
  [DialogNames.APPOINTMENTS_LIST_FOR_ESTIMATE]: AppointmentsListDialog,
  [DialogNames.ASSIGN_IMAGE_TO_PATIENT]: ImagingAssignmentDialog,
  [DialogNames.ASSIGN_TASKS]: AssignTasksDialog,
  [DialogNames.ASSIGN_TEAM_MEMBER]: TeamMemberAssignmentDialog,
  [DialogNames.ASSIGNED_VET_VALIDATION]: LabOrderAssignmentDialog,
  [DialogNames.ATTACHMENT_HISTORY]: AttachmentHistoryDialog,
  [DialogNames.ATTACHMENT_LIGHTBOX]: AttachmentLightbox,
  [DialogNames.AUTO_REPLY_MESSAGE_PREVIEW_DIALOG]:
    AutoReplyMessagePreviewDialog,
  [DialogNames.AUTOMATED_PATIENT_UPDATE_ALERT]:
    AutomatedPatientUpdateAlertDialog,
  [DialogNames.AUTOMATIC_COMMUNICATION_PREVIEW]:
    AutomaticCommunicationPreviewDialog,
  [DialogNames.AUTOMATIC_COMMUNICATION]: AutomaticCommunicationDialog,
  [DialogNames.AVAILABILITY_RULE]: AvailabilityRuleDialog,
  [DialogNames.BATCH_IMAGING_ORDERS_MANAGEMENT]:
    BatchImagingOrderManagementDialog,
  [DialogNames.BATCH_IMAGING_ORDERS_MANAGEMENT_V2]:
    BatchImagingOrderManagementDialogV2,
  [DialogNames.BATCH_INVOICE]: BatchInvoiceDialog,
  [DialogNames.BOOP_SLA_MESSAGES_PREVIEW_DIALOG]: BoopSlaMessagesPreviewDialog,
  [DialogNames.BULK_ADJUSTMENT_DIALOG]: BulkAdjustmentDialog,
  [DialogNames.BULK_PRICES_CLOSE]: BulkPricesCloseDialog,
  [DialogNames.BUNDLE_CUSTOM_PRICE]: BundleCustomPriceDialog,
  [DialogNames.BUNDLE_TASK]: BundleTaskDialog,
  [DialogNames.BUNDLE]: BundleDialog,
  [DialogNames.CANCEL_MEMBERSHIP_DETAILS]: CancelMembershipDetailsDialog,
  [DialogNames.CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP]:
    CancelFutureAppointmentsAndMembershipDialog,
  [DialogNames.CANCEL_MEMBERSHIP]: CancelMembershipDialog,
  [DialogNames.CHANGE_PET_PARENT]: ChangePetParentDialog,
  [DialogNames.CHARGE_SHEET_LAB_ORDER_MANAGEMENT_DIALOG]:
    ChargeSheetLabOrderManagementDialog,
  [DialogNames.CHEWY_PAYMENT_DIALOG]: ChewyPaymentDialog,
  [DialogNames.CHOOSE_AVATAR]: ChooseAvatarDialog,
  [DialogNames.CHOOSE_SHIPMENT_ITEM_DIALOG]: ChooseShipmentItemDialog,
  [DialogNames.CHOOSE_ZOOM_USER_DIALOG]: ChooseZoomUserDialog,
  [DialogNames.CLIENT_DETAILS_EDIT]: ClientDetailsEditDialog,
  [DialogNames.CLIENT_DUPLICATES]: ClientDuplicatesDialog,
  [DialogNames.CLIENT_NEW]: ClientDialog,
  [DialogNames.CLIENT_PREFERENCES]: ClientPreferencesDialog,
  [DialogNames.CLIENT_SURVEY]: ClientSurveyDialog,
  [DialogNames.CO_PET_PARENT_DIALOG]: CoPetParentDialog,
  [DialogNames.COMMUNICATION_SAVE_AS_CLIENT_EMAIL]: SaveAsClientEmailDialog,
  [DialogNames.CONFIRM_CLOSE_DIALOG]: ConfirmCloseDialog,
  [DialogNames.CONTACT]: ContactDialog,
  [DialogNames.CONVERSATION_ATTACHMENT_DIALOG]: ConversationAttachmentDialog,
  [DialogNames.CONVERSATION_DELETE_DIALOG]: ConversationDeleteDialog,
  [DialogNames.CONVERSATION_INTERNAL_NOTE]: ConversationInternalNoteDialog,
  [DialogNames.CONVERSATION_MULTI_ATTACHMENT_DIALOG]:
    ConversationMultiAttachmentDialog,
  [DialogNames.CORE_MIGRATION_DIALOG]: CoreMigrationDialog,
  [DialogNames.COVETRUS_INTEGRATION_DIALOG]: CovetrusIntegrationDialog,
  [DialogNames.CREATE_PRACTICE_GROUP]: CreatePracticeGroupDialog,
  [DialogNames.CREATE_PRACTICE]: CreatePracticeDialog,
  [DialogNames.CREATED_CONVERSATIONS_INFO_DIALOG]:
    CreatedConversationsInfoDialog,
  [DialogNames.CREDIT_ADJUSTMENT_DIALOG]: CreditAdjustmentDialog,
  [DialogNames.DECLINE_FINALIZED_LAB_TEST]: DeclineFinalizedLabTestDialog,
  [DialogNames.DECLINE_LAB_ORDER]: DeclineLabOrderDialog,
  [DialogNames.DELETE_LINE_ITEMS]: DeleteLineItemDialog,
  [DialogNames.DIAGNOSIS_HISTORY]: DiagnosisHistoryDialog,
  [DialogNames.DISMISSIBLE_ALERT]: PuiAlert,
  [DialogNames.DOCTOR_LICENSE_DIALOG]: DoctorLicenseDialog,
  [DialogNames.DOCTOR_SIGNATURE_DIALOG]: DoctorSignatureDialog,
  [DialogNames.DOCUMENT]: DocumentDialog,
  [DialogNames.DOCUMENT_FORM_PREVIEW_DIALOG]: DocumentFormPreviewDialog,
  [DialogNames.DOCUMENT_RADIO_GROUP_DIALOG]: DocumentRadioGroupDialog,
  [DialogNames.DRUG_PRESCRIPTION_DIALOG]: DrugPrescriptionDialog,
  [DialogNames.DRUG_REFILL_DIALOG]: DrugRefillDialog,
  [DialogNames.GLOBAL_VARIATION_SELECT]: GlobalVariationSelectDialog,
  [DialogNames.DYMO_ERROR]: DymoErrorDialog,
  [DialogNames.EMAIL_APPOINTMENT_CONFIRMATION]:
    EmailAppointmentConfirmationDialog,
  [DialogNames.EMAIL_APPOINTMENT]: EmailAppointmentDialog,
  [DialogNames.EMAIL_ATTACHMENT_DIALOG]: EmailAttachment,
  [DialogNames.EMAIL_CHARGE_SHEET]: EmailChargeSheetDialog,
  [DialogNames.EMAIL_INVOICE]: EmailInvoiceDialog,
  [DialogNames.EMAIL_LAB_ORDER_RESULT]: EmailOrderResultDialog,
  [DialogNames.EMAIL_POSTED_INVOICE]: EmailPostedInvoiceDialog,
  [DialogNames.EMAIL_PRESCRIPTION]: EmailPrescriptionDialog,
  [DialogNames.EMAIL_TEMPLATE_PREVIEW_DIALOG]: EmailPreviewDialog,
  [DialogNames.EMERGENCY_CONTACT_DIALOG]: EmergencyContactDialog,
  [DialogNames.EMPLOYEE_ID]: EmployeeIdDialog,
  [DialogNames.ESTIMATE_APPROVAL_DIALOG]: EstimateApprovalDialog,
  [DialogNames.ESTIMATES_LIST]: EstimatesListDialog,
  [DialogNames.EVENT]: AppointmentDialog,
  [DialogNames.EXPANDER_CONFIRM_CLOSE]: ExpanderConfirmCloseDialog,
  [DialogNames.FINALIZE_LAB_ORDER]: FinalizeLabOrderDialog,
  [DialogNames.FINALIZE_CHEWY_ACTIVE_RX_DIALOG]: FinalizeChewyActiveRxDialog,
  [DialogNames.FOOD_PRESCRIPTION_DIALOG]: FoodPrescriptionDialog,
  [DialogNames.FOOD_REFILL_DIALOG]: FoodRefillDialog,
  [DialogNames.GENERAL_INFORMATION_LOCATION]: GeneralInformationLocationDialog,
  [DialogNames.IMAGING_ORDER]: ImagingOrderDialog,
  [DialogNames.IMPORT_ALREADY_DONE]: ImportAlreadyDoneDialog,
  [DialogNames.INVENTORY]: InventoryDialog,
  [DialogNames.INVOICE_MISSING_FIELDS_DIALOG]: InvoiceMissingFieldsDialog,
  [DialogNames.INVOICE]: InvoiceDialog,
  [DialogNames.INVOICE_DISCOUNT_REASON]: InvoiceDiscountReasonDialog,
  [DialogNames.KIOSK_PREVIEW]: KioskPreviewDialog,
  [DialogNames.KIOSK]: KioskDialog,
  [DialogNames.LAB_ORDER_MANAGEMENT_DIALOG]: LabOrderManagementDialog,
  [DialogNames.LAB_RESULT_ASSIGNMENT]: LabResultAssignmentDialog,
  [DialogNames.LAB_TEST_ATTACHMENT_DIALOG]: LabTestAttachmentDialog,
  [DialogNames.LAB_TEST_PRICE_VALIDATION]: LabTestPriceValidationDialog,
  [DialogNames.LAB_TEST_PRICE]: LabTestPriceDialog,
  [DialogNames.LAB_VENDOR_PROVIDERS_DIALOG]: LabVendorProvidersDialog,
  [DialogNames.LICENSE]: LicenseDialog,
  [DialogNames.LINK_CHEWY_ACCOUNT]: LinkChewyAccountDialog,
  [DialogNames.LINK_INDIVIDUAL_PATIENT_DIALOG]: LinkIndividualPatientDialog,
  [DialogNames.LOST_ACCESS_TO_CLINIC_ALERT_DIALOG]:
    LostAccessToClinicAlertDialog,
  [DialogNames.MATCH_PET_PATIENT_DIALOG]: MatchPetPatientDialog,
  [DialogNames.MEMBERSHIP_PAYMENT_DETAILS]: MembershipPaymentDetailsDialog,
  [DialogNames.MEMBERSHIP_SIGN_UP]: MembershipSignUpDialog,
  [DialogNames.MIGRATION_ACTIVATION_TOKEN]: MigrationActivationTokenDialog,
  [DialogNames.MIGRATION_UPLOAD_ADDITIONAL_FILES]: UploadAdditionalFilesDialog,
  [DialogNames.MIGRATION_V2_DIALOG]: MigrationV2Dialog,
  [DialogNames.MISSING_RESOURCE]: MissingResourceDialog,
  [DialogNames.MULTI_ATTACHMENT_HISTORY]: MultiAttachmentHistoryDialog,
  [DialogNames.MULTI_VALIDATION_ERROR]: MultiValidationErrorDialog,
  [DialogNames.MULTIPLE_SELECT_DIALOG]: MultipleSelectDialog,
  [DialogNames.MULTIPLE_TASKS]: MultipleTasksDialog,
  [DialogNames.NEW_BENCHMARKING_ACTIVATION_KEY]:
    BenchmarkingActivationKeyDialog,
  [DialogNames.NEW_CONVERSATION_WITH_STEPS]: NewConversationWithStepsDialog,
  [DialogNames.NEW_REMINDER_PROTOCOL_GROUP]: NewReminderProtocolGroupDialog,
  [DialogNames.NOTE_HISTORY]: NoteHistoryDialog,
  [DialogNames.NOT_FINALIZED_ORDERS]: NotFinalizedOrderDialog,
  [DialogNames.NOTES]: NotesDialog,
  [DialogNames.NOTIFICATION_STYLES_PREVIEW_DIALOG]:
    NotificationStylesPreviewDialog,
  [DialogNames.ON_GOING_STANDARDIZATION_DIALOG]: OnGoingStandardizationDialog,
  [DialogNames.ON_HAND_CATALOG_PRINT]: OnHandCatalogPrintDialog,
  [DialogNames.ORDER_RESULTS]: OrderResultsDialog,
  [DialogNames.OTC_VALIDATION]: OTCValidationDialog,
  [DialogNames.PATIENT_MEMBERSHIP_PAYMENT_INFO]:
    PatientMembershipPaymentInfoDialog,
  [DialogNames.PATIENT_MEMBERSHIP_PAYMENT_LINK]:
    PatientMembershipPaymentLinkDialog,
  [DialogNames.PATIENT_MEMBERSHIP]: PatientMembershipDialog,
  [DialogNames.PATIENT_PREFERENCES]: PatientPreferencesDialog,
  [DialogNames.PATIENT_MISSING_FIELDS_DIALOG]: PatientMissingFieldsDialog,
  [DialogNames.PATIENT]: PatientDialog,
  [DialogNames.PATIENTS_LIST]: PatientsListDialog,
  [DialogNames.PAYMENT_DETAILS]: PaymentDetailsDialog,
  [DialogNames.PAYMENT_REQUEST]: PaymentRequestDialog,
  [DialogNames.PET_FRIEND]: PetFriendDialog,
  [DialogNames.PET_PARENT_CHANGED_DIALOG]: PetParentChangedDialog,
  [DialogNames.PRACTICE_CLONING_EXPORT_DIALOG]: PracticeCloningExportDialog,
  [DialogNames.PRACTICE_CLONING_IMPORT_DIALOG]: PracticeCloningImportDialog,
  [DialogNames.PRESCRIPTION_DECLINE_REASON]: PrescriptionDeclineReasonDialog,
  [DialogNames.PRESCRIPTION_MISSING_FIELDS_DIALOG]:
    PrescriptionMissingFieldsDialog,
  [DialogNames.PREVIEW_REMINDER_TEMPLATE]: PreviewReminderTemplateDialog,
  [DialogNames.PREVIEW_WELLNESS_PROMOTION]: PreviewWellnessPromotionDialog,
  [DialogNames.PRICE]: PriceDialog,
  [DialogNames.PRINT_CAGE_LABEL]: PrintCageLabelDialog,
  [DialogNames.PRINT_CHARGE_SHEET]: PrintChargeSheet,
  [DialogNames.PRINT_FOLDER_LABEL]: PrintFolderLabelDialog,
  [DialogNames.PRINT_IN_HOUSE_ORDER_LABEL]: PrintInHouseOrderLabelDialog,
  [DialogNames.PRINT_INVOICE]: PrintInvoice,
  [DialogNames.PRINT_LAB_ORDER_LABEL]: PrintLabOrderLabelDialog,
  [DialogNames.PRINT_POSTED_INVOICE]: PrintPostedInvoice,
  [DialogNames.PRINT_PRACTICE_ADDRESS_LABEL]: PrintPracticeAddressLabelDialog,
  [DialogNames.PROFILE]: ProfileDialog,
  [DialogNames.PROOF_OF_VACCINATION]: ProofOfVaccinationDialog,
  [DialogNames.QUESTION]: QuestionDialog,
  [DialogNames.RABIES_VACCINATIONS]: RabiesVaccinationsDialog,
  [DialogNames.RECURRING_EVENT]: RecurringEventDialog,
  [DialogNames.REDEEM_LOYALTY_POINTS]: RedeemLoyaltyPointsDialog,
  [DialogNames.REFUND_PAYMENT]: RefundPaymentDialog,
  [DialogNames.REMINDER_COMMUNICATION]: ReminderCommunicationDialog,
  [DialogNames.REMINDER_DETAILS]: ReminderDetailsDialog,
  [DialogNames.REMINDER_PROTOCOL]: ReminderProtocolDialog,
  [DialogNames.REMINDERS_LIST]: RemindersListDialog,
  [DialogNames.REMOVE_FINALIZED_LAB_TEST]: RemoveFinalizedLabTestDialog,
  [DialogNames.REMOVE_LAB_ORDER]: RemoveLabOrderDialog,
  [DialogNames.REMOVE_MEMBER]: RemoveMemberDialog,
  [DialogNames.REMOVE_ORDERS_FROM_POSTED_INVOICE]:
    RemoveOrdersFromPostedInvoiceDialog,
  [DialogNames.REPEAT_TASKS]: RepeatTasksDialog,
  [DialogNames.REPORT_UNAVAILABLE]: ReportUnavailableDialog,
  [DialogNames.REQUIRED_DEPOSIT]: RequiredDepositDialog,
  [DialogNames.REVIEW_EXCEPTIONS]: ReviewExceptionsDialog,
  [DialogNames.RHAPSODY_PAY_SETUP]: RhapsodyPaySetupDialog,
  [DialogNames.ROLES]: RolesDialog,
  [DialogNames.SAVE_INVOICE_ALERT]: SaveInvoiceAlert,
  [DialogNames.SELECT_ATTACHMENT]: SelectAttachmentDialog,
  [DialogNames.SELECT_BENEFIT_APPOINTMENT_TYPE]:
    SelectBenefitAppointmentTypeDialog,
  [DialogNames.SELECT_CONTACT]: SelectContactDialog,
  [DialogNames.SELECT_PIMS]: SelectPimsDialog,
  [DialogNames.SEND_BOOP_LINK]: SendBoopLinkDialog,
  [DialogNames.SEND_HYBRID_MEMBERSHIP_INVITE]: SendHybridMembershipInviteDialog,
  [DialogNames.SHARE_MEDICAL_HISTORY]: MedicalHistoryCardDialog,
  [DialogNames.SHIPMENT_ITEM]: ShipmentItemDialog,
  [DialogNames.SHIPMENT]: ShipmentDialog,
  [DialogNames.SIGNATURE_DIALOG]: SignatureDialog,
  [DialogNames.SOAP_ATTACHMENT]: SoapAttachmentDialog,
  [DialogNames.SOAP_AUTOCHARGE_ALERT]: SoapAutochargedAlert,
  [DialogNames.SOAP_EXAM_FINDINGS_VALIDATION_ERROR]:
    SoapExamFindingsValidationErrorDialog,
  [DialogNames.SOAP_FINALIZED_ALERT]: SoapFinalizedAlert,
  [DialogNames.SOAP_MULTI_ATTACHMENT]: SoapMultiAttachmentDialog,
  [DialogNames.SOAP_NOTES_VALIDATION_ERROR]: SoapNotesValidationErrorDialog,
  [DialogNames.SOAP_ORDER_VALIDATION_ERROR_ALERT]:
    SoapOrderValidationErrorDialog,
  [DialogNames.SOAP_TEMPLATE_TAB]: SoapTemplateTabDialog,
  [DialogNames.SOAP_VALIDATION]: SoapValidationDialog,
  [DialogNames.SOAP_WRAP_UP_LEAVE]: SoapWrapUpLeaveDialog,
  [DialogNames.SPACE]: SpaceDialog,
  [DialogNames.SPACE_SELECT]: SpaceSelectDialog,
  [DialogNames.SWITCH_USER]: SwitchUserDialog,
  [DialogNames.TASK]: TaskDialog,
  [DialogNames.TEXT_ENTRY]: TextEntryDialog,
  [DialogNames.TEAM_MEMBER]: TeamMemberDialog,
  [DialogNames.TERMS_AND_CONDITIONS]: TermsAndConditionsDialog,
  [DialogNames.TIME_TRACKER_EDIT]: TimeTrackerEditDialog,
  [DialogNames.TIME_TRACKER]: TimeTrackerDialog,
  [DialogNames.TYPOGRAPHY_DIALOG]: TypographyDialog,
  [DialogNames.UNDO_PAYMENT]: UndoPaymentDialog,
  [DialogNames.UPLOAD_IMAGE]: UploadImageDialog,
  [DialogNames.UPLOAD_SESSION_FILES]: UploadSessionFilesDialog,
  [DialogNames.VACCINE_CERTIFICATE_PREVIEW]: VaccineCertificatePreviewDialog,
  [DialogNames.VACCINE_CERTIFICATE_VALIDATION]:
    VaccineCertificateValidationDialog,
  [DialogNames.VACCINE_DETAILS]: VaccineDetailsDialog,
  [DialogNames.VACCINE_HISTORY]: VaccineHistoryDialog,
  [DialogNames.VALIDATION_ERROR]: ValidationErrorDialog,
  [DialogNames.VARIATION]: VariationDialog,
  [DialogNames.VIEW_ORDER_RESULTS_FRAME]: OrderResultFrameDialog,
  [DialogNames.VITALS_CONFIRMATION]: VitalsConfirmationDialog,
  [DialogNames.VITALS]: VitalsDialog,
  [DialogNames.WELLNESS_PLAN_BENEFITS]: WellnessPlanBenefitsDialog,
  [DialogNames.WELLNESS_PLAN_EXTRA]: WellnessPlanExtraDialog,
  [DialogNames.WELLNESS_PLAN_PACKAGE]: WellnessPlanPackageDialog,
  [DialogNames.WELLNESS_PLAN_SAVINGS]: WellnessPlanSavingsDialog,
  [DialogNames.WELLNESS_PLAN_TIER]: WellnessPlanTierDialog,
}

export default DialogComponentMap

export type DialogComponentMapType = typeof DialogComponentMap
