import React from 'react'

import { useLockableComponent } from '~/store/hooks/soap'

import DifferentialDiagnoses from './diff-diagnoses/DifferentialDiagnoses'
import DifferentialDiagnosesLocked from './diff-diagnoses/DifferentialDiagnosesLocked'

interface DiffDxPageProps {
  editDisabled: boolean
}

const DiffDxPage: React.FC<DiffDxPageProps> = ({ editDisabled }) => {
  const DifferentialDiagnosesComponent = useLockableComponent(
    DifferentialDiagnoses,
    DifferentialDiagnosesLocked,
  )

  return <DifferentialDiagnosesComponent editDisabled={editDisabled} />
}

export default DiffDxPage
