import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  HtmlNotesPreview,
  LanguageUtils,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { AddNote } from '@pbt/pbt-ui-components/src/icons'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import { BodySystemState, SOAPStep } from '~/constants/SOAPStates'
import {
  editFindingsState,
  fetchSoapFindingsContainer,
  updateFindingToExpand,
} from '~/store/actions/soap'
import {
  getFindingsState,
  getFindingsStaticList,
  getIsExamFindingsLoading,
  getIsSaving,
  getPendingFindingsState,
  getSoapBusinessId,
  getSoapId,
} from '~/store/reducers/soap'
import { FindingState, StaticFinding } from '~/types'
import useDialog from '~/utils/useDialog'

// @ts-ignore
import { groupFindings } from '../../examination/findingsUtils'
// @ts-ignore
import { containsFinding } from '../../utils/examinationUtils'
import FindingsNode from './FindingsNode'

const useStyles = makeStyles(
  (theme) => ({
    findings: {
      paddingTop: theme.spacing(1),
    },
    findingsGroup: {
      '&:not(:last-of-type)': {
        paddingBottom: theme.spacing(1),
      },
    },
    noteIconButton: {
      padding: theme.spacing(0.5),
      marginLeft: 'auto',
    },
  }),
  { name: 'Examination' },
)

interface ExaminationProps {
  className?: string
  editDisabled?: boolean
  expandedItems: Record<string, boolean>
}

const Examination = ({
  className,
  editDisabled,
  expandedItems,
}: ExaminationProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Soap'])

  const findingsState = useSelector(getFindingsState)
  const findingsStaticList = useSelector(getFindingsStaticList)
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const isLoading = useSelector(getIsExamFindingsLoading)
  const pendingFindingsState = useSelector(getPendingFindingsState)

  const { ncFindings, onlFindings, wnlFindings } = groupFindings(findingsState)

  const onFindingClick = (categoryId: string, finding: StaticFinding) => {
    dispatch(updateFindingToExpand({ categoryId, findingId: finding.id }))
  }

  const isAllowedFinding = (id: string, finding: StaticFinding) => {
    const category: StaticFinding = Utils.findById(id, findingsStaticList)
    return containsFinding(category, finding.id)
  }

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.SOAP_RAIL_EXAMINATION,
  })
  const [openNotesDialog] = useDialog(DialogNames.NOTES)

  const handleDelete = (finding: StaticFinding, onlFinding: FindingState) => {
    const deleteFinding = () =>
      dispatch(
        editFindingsState({
          bodySystem: onlFinding,
          finding: { id: finding.id },
          state: BodySystemState.ONL,
          uncheckFinding: true,
        }),
      )

    openConfirmAlert({
      message: t('Common:YOU_WILL_REMOVE_SOMETHING', {
        something: finding.name,
      }),
      onConfirm: (proceed: boolean) => proceed && deleteFinding(),
    })
  }

  const handleBodySystemNotesSave = (
    notes: string,
    category?: StaticFinding,
  ) => {
    if (category) {
      dispatch(
        editFindingsState({
          bodySystem: category,
          state: findingsState?.[category.id]?.state || BodySystemState.NC,
          notes,
        }),
      )
    }
  }

  const handleShowNoteDialog = (
    bodySystemId: string,
    bodySystemName: string,
  ) => {
    const category = R.find(R.propEq('id', bodySystemId), findingsStaticList)

    openNotesDialog({
      name: bodySystemName,
      notes: findingsState?.[bodySystemId]?.notes,
      onUpdateNotes: (notes: string) =>
        handleBodySystemNotesSave(notes, category),
      isLoadingSelector: getIsSaving,
    })
  }

  const getFindingsMapWithNotes = R.filter(R.pipe(R.prop('notes'), Boolean))

  const isExaminationExpanded = expandedItems?.[SOAPStep.EXAMINATION]

  // Get the most updated findings when Examination is expanded
  // skip fetching when editing findings
  useEffect(() => {
    if (soapId && isExaminationExpanded && !pendingFindingsState) {
      dispatch(fetchSoapFindingsContainer(soapId, soapBusinessId))
    }
  }, [soapId, isExaminationExpanded, pendingFindingsState])

  const renderSystems = (bodySystems: FindingState[] = [], title: string) => (
    <>
      {bodySystems.length > 0 && (
        <Text strong mt={0.75} variant="body2">
          {title}
        </Text>
      )}
      {bodySystems.map((bodySystem) => {
        const bodySystemName = LanguageUtils.getTranslatedFieldName(bodySystem)
        return (
          <Grid item className={classes.findingsGroup} key={bodySystem?.id}>
            <Grid container>
              <Text strong mt={0.75} variant="body2">
                {bodySystemName}
              </Text>
              <IconButton
                className={classes.noteIconButton}
                size="large"
                onClick={() =>
                  handleShowNoteDialog(bodySystem?.id, bodySystemName)
                }
              >
                <AddNote filled={Boolean(bodySystem?.notes)} />
              </IconButton>
            </Grid>
            {bodySystem?.notes && (
              <HtmlNotesPreview includeLabel notes={bodySystem.notes} />
            )}
            {Object.values(bodySystem?.findings || {}).map((finding) => (
              <FindingsNode
                className={classes.findings}
                editDisabled={editDisabled}
                finding={finding}
                key={finding.id}
                linkDisabled={!isAllowedFinding(bodySystem?.id, finding)}
                onDelete={() => handleDelete(finding, bodySystem)}
                onFindingClick={() => onFindingClick(bodySystem?.id, finding)}
              />
            ))}
          </Grid>
        )
      })}
    </>
  )

  return (
    <Grid item className={className}>
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <>
          {renderSystems(
            onlFindings,
            t('Soap:EXAMINATION.OUTSIDE_NORMAL_LIMITS'),
          )}
          {renderSystems(
            getFindingsMapWithNotes(wnlFindings),
            t('Soap:EXAMINATION.WITHIN_NORMAL_LIMITS'),
          )}
          {renderSystems(
            getFindingsMapWithNotes(ncFindings),
            t('Soap:EXAMINATION.NOT_CHECKED'),
          )}
        </>
      )}
    </Grid>
  )
}

export default Examination
