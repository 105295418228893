import * as R from 'ramda'
import {
  Constant,
  ConstantMap,
  FieldObject,
  LanguageUtils,
  NamedEntity,
  Nil,
  NumberUtils,
  UnitsState,
  UnitUtils,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  TemperatureTypes,
  WeightTypes,
} from '@pbt/pbt-ui-components/src/localization'

import { UnitsWithLabels } from '~/constants/units'
import { Vital, VitalConfigItem, VitalTableData, VitalValue } from '~/types'

const FIELD_DIVIDER = '_id_'
const FIELD_PREFIX_VALUE = 'value_id_'
const FIELD_PREFIX_NOTES = 'notes_id_'

export const getVitalValueField = (id: keyof VitalValue, fields: FieldObject) =>
  fields[`${FIELD_PREFIX_VALUE}${id}`]
export const getVitalNotesField = (id: keyof VitalValue, fields: FieldObject) =>
  fields[`${FIELD_PREFIX_NOTES}${id}`]

export const getPoundsAndOunces = (decimalPounds = '') => {
  const [integer = 0, fraction = 0] = decimalPounds.toString().split('.')
  const ounces = Utils.round(Number(`0.${fraction}`) * 16, 2)

  return [Number(integer), ounces]
}

export const getDecimalPounds = (
  weight: { base: string | number; fraction: string | number } | string,
) => {
  const primitiveWeight = typeof weight === 'string'
  const pounds = primitiveWeight ? weight : weight?.base
  const ounces = primitiveWeight ? '0' : weight?.fraction
  return primitiveWeight
    ? parseFloat(weight)
    : parseFloat(pounds.toString()) +
        (1 / 16) * parseFloat(ounces.toString() || '0')
}

export const formatUnits = (value: number | string | Nil) =>
  R.isNil(value)
    ? '-'
    : `${
        typeof value === 'number'
          ? NumberUtils.formatNumberWithDecimal(value)
          : value
      }`

const getFormattedConstants = (constants: ConstantMap, optionsType: string) => {
  const currentConstant: Constant = constants[
    optionsType as keyof ConstantMap
  ] as Constant

  return Array.isArray(currentConstant)
    ? currentConstant.map((item) =>
        item?.id ? item : { id: `${item}`, name: item },
      )
    : Object.values(currentConstant)
}

const getUnits = (
  optionsType: string | undefined,
  constants: ConstantMap,
  options: NamedEntity[] | undefined,
  value: any,
) => {
  const currentUnits = optionsType
    ? getFormattedConstants(constants, optionsType)
    : options || []
  const rawName = LanguageUtils.getConstantTranslatedName(
    value?.toString(),
    currentUnits,
    value?.toString(),
  )
  return LanguageUtils.getTranslatedNameByRawName(rawName, currentUnits)
}

export const getVitalDisplayValue = (
  value: any,
  configItem: VitalConfigItem,
  constants: ConstantMap,
  unitsState?: UnitsState,
  format: boolean = true,
) => {
  const { options, optionsType } = configItem.formInput

  const needsConverting = !options && !optionsType
  const convertedUnits =
    needsConverting && unitsState
      ? UnitUtils.convertUnits(configItem.id, value?.id ?? value, unitsState)
      : getUnits(optionsType, constants, options, value?.id ?? value)

  return format ? formatUnits(convertedUnits) : convertedUnits
}

export const getVitalPostfix = (
  unit: { id: string } | undefined,
  config: VitalConfigItem,
) => {
  if (!unit) {
    return UnitsWithLabels.includes(config?.formInput?.units ?? '')
      ? config.formInput.units
      : ''
  }

  return unit.id
}

export const getVitalLabel = (
  value: any,
  config: VitalConfigItem,
  constants: ConstantMap,
  unit?: { id: string },
  noPostfix?: any,
) => {
  const vitalValue = unit
    ? getVitalDisplayValue(value?.id || value, config, constants, {
        [config.id]: unit.id,
      } as UnitsState)
    : getVitalDisplayValue(value?.id || value, config, constants)
  const unitsPostfix =
    !R.isNil(value) && !noPostfix && getVitalPostfix(unit, config)

  return [vitalValue, unitsPostfix].filter(Boolean).join('\u00A0')
}

export const serializeVitals = (vital: any, unitsState: UnitsState) => {
  if (vital.weight?.value) {
    const serializedWeight =
      unitsState.weight === WeightTypes.KG
        ? UnitUtils.kgToLbs(vital.weight.value.base || vital.weight.value)
        : getDecimalPounds(vital.weight.value)
    vital.weight.value = Utils.round(serializedWeight, 4)
  }

  if (vital.temperature && unitsState.temperature === TemperatureTypes.C) {
    vital.temperature.value = UnitUtils.celsiusToFahrenheit(
      vital.temperature.value,
    )
  }

  return vital
}

const isValidLength = (str: string, intLength = 4, fractionLength = 4) => {
  const [integerPart, fractionPart] = str.split('.')
  return (
    (!integerPart || integerPart.length <= intLength) &&
    (!fractionPart || fractionPart.length <= fractionLength)
  )
}

const numericValueTester = /^\d+?\.?\d*$/
export const handleUnitValueChange = (
  onChange: (value: string) => void,
  str: string,
) => {
  const isAcceptable = numericValueTester.test(str)

  onChange(isAcceptable && isValidLength(str) ? str : '')
}

export function getVitalIdOrValue(
  value:
    | string
    | number
    | boolean
    | { base: number; fraction: number }
    | undefined,
): string | number | boolean | { base: number; fraction: number } | undefined {
  if (typeof value === 'object' && value !== null && 'id' in value) {
    return (value as { id: string | number }).id
  }
  return value
}

export const getTableData = ({
  config,
  vitals,
  unitsState,
  constantMap,
  withUnits = true,
}: {
  config: VitalConfigItem[]
  constantMap: ConstantMap
  unitsState: UnitsState
  vitals: Vital[]
  withUnits?: boolean
}): Record<string, VitalTableData[]> =>
  config.reduce(
    (acc, configItem) => ({
      ...acc,
      [configItem.id]: vitals.map((item) => {
        const vital = item[configItem.id]
        const unitId = unitsState[configItem.id as keyof UnitsState]

        if (withUnits) {
          const vitalValue = getVitalLabel(
            getVitalIdOrValue(vital?.value),
            configItem,
            constantMap,
            { id: unitId },
          )

          return {
            value: vitalValue,
            notes: vital?.notes,
            finalized: item?.finalized,
          }
        }

        return {
          value: getVitalDisplayValue(
            getVitalIdOrValue(vital?.value),
            configItem,
            constantMap,
            unitsState,
          ),
          notes: vital?.notes,
          finalized: item?.finalized,
        }
      }),
    }),
    {},
  )

export const fieldNameToVitalId = (name: string | undefined) =>
  name?.split(FIELD_DIVIDER)[1]

export const getVitalsState = (
  fields: FieldObject,
  soapId: string | Nil,
  unitsState: UnitsState,
) => {
  const valueFields = Object.keys(fields)
    .map((key) =>
      fields[key].value || fields[key].value !== fields[key].initialValue
        ? fields[key]
        : undefined,
    )
    .filter(Boolean)

  const initialVital = soapId ? { soapId } : {}

  const vital = valueFields.reduce((acc, field) => {
    const vitalName = fieldNameToVitalId(field?.name) ?? ''
    return {
      ...acc,
      [vitalName]: {
        value: getVitalValueField(vitalName as keyof VitalValue, fields)?.value,
        notes: getVitalNotesField(vitalName as keyof VitalValue, fields)?.value,
      },
    }
  }, initialVital)

  return serializeVitals(vital, unitsState)
}

export const getVitalsFields = (
  unitsState: UnitsState,
  vitalsConfig: VitalConfigItem[],
  vitals?: any,
) =>
  R.pipe(
    R.map(({ id }) => [
      {
        name: `${FIELD_PREFIX_VALUE}${id}`,
        initialValue: vitals
          ? UnitUtils.convertUnits(id, vitals[id]?.value, unitsState) || ''
          : '',
      },
      {
        name: `${FIELD_PREFIX_NOTES}${id}`,
        initialValue: vitals ? vitals[id]?.notes || '' : '',
      },
    ]),
    R.flatten as (list: any[]) => any[],
  )(vitalsConfig)

export const hasUnsavedVitalsByValue = R.pipe(
  R.values,
  R.any(R.prop('value') as any),
)
