import React from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { formatNumberWithDecimal } from '@pbt/pbt-ui-components/src/utils/numberUtils'

import Typography from '~/components/elements/Typography/Typography'

import { useGetNoShowCancellationPenaltyFee } from './useGetNoShowCancellationPenaltyFee'

const useStyles = makeStyles(
  (theme) => ({
    warningText: {
      color: theme.colors.important,
    },
  }),
  { name: 'CancellationPolicyText' },
)

export interface CancellationPolicyTextProps {
  isCancelDialog: boolean
}

const CancellationPolicyText = ({
  isCancelDialog,
}: CancellationPolicyTextProps) => {
  const { t } = useTranslation(['Common'])
  const classes = useStyles()

  const { penaltyFee, isPenaltyFeeQueryLoading, penaltyFeeQueryError } =
    useGetNoShowCancellationPenaltyFee()

  if (isPenaltyFeeQueryLoading) {
    return <Skeleton sx={{ mb: 1 }} variant="rectangular" width="100%" />
  }

  if (penaltyFeeQueryError || penaltyFee === null) {
    return (
      <Typography.Paragraph className={classes.warningText}>
        {isCancelDialog
          ? t('Common:LATE_CANCELLATION_FEE_MESSAGE.DEFAULT')
          : t('Common:CANCELLATION_POLICY_CONTENT.DEFAULT')}
      </Typography.Paragraph>
    )
  }

  const amountToDisplay = formatNumberWithDecimal(penaltyFee)

  return (
    <Typography.Paragraph className={classes.warningText}>
      {isCancelDialog
        ? t('Common:LATE_CANCELLATION_FEE_MESSAGE.WITH_AMOUNT', {
            amount: amountToDisplay,
          })
        : t('Common:CANCELLATION_POLICY_CONTENT.WITH_AMOUNT', {
            amount: amountToDisplay,
          })}
    </Typography.Paragraph>
  )
}

export default CancellationPolicyText
