import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { isEmpty, pluck } from 'ramda'
import { PermissionArea, Utils } from '@pbt/pbt-ui-components'

import { DiagnosisStatusName } from '~/constants/diagnosis'
import { editDifferentialDiagnosesState } from '~/store/actions/soap'
import {
  clearSoapDiagnoses,
  clearSoapDiagnosesFilters,
  fetchDiagnosesFilters,
  fetchSoapDiagnoses,
  getDiagnosesData,
  getDiagnosesFilters,
  getDiagnosesIsLoading,
  getDiffDxSearchResults,
  searchDiagnoses,
} from '~/store/duck/soapDiagnoses'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getDiagnosisStatus } from '~/store/reducers/constants'
import {
  getDiagnosesState,
  getPatientId,
  getSoapId,
} from '~/store/reducers/soap'
import { DiagnosesState, SoapDiagnose } from '~/types'
import { getSoapContainerHeight } from '~/utils'

import DiffDxExpandedDetails from '../../list-with-filters/DiffDxExpandedDetails'
import ListWithFilters from '../../list-with-filters/ListWithFilters'

const useStyles = makeStyles(
  (theme) => ({
    listWithFiltersContainer: {
      height: getSoapContainerHeight(theme),
    },
  }),
  { name: 'DifferentialDiagnoses' },
)

const defaultIsCheckedItem = (item: any, selectedItems: any[]) =>
  selectedItems.some(({ id }) => id === item.id)

interface DifferentialDiagnosesProps {
  editDisabled: boolean
}

const DifferentialDiagnoses = ({
  editDisabled,
}: DifferentialDiagnosesProps) => {
  const classes = useStyles()
  const listRef = useRef()
  const dispatch = useDispatch()
  const { t } = useTranslation('Search')

  const patientId = useSelector(getPatientId)
  const filters = useSelector(getDiagnosesFilters)
  const diagnosesData = useSelector(getDiagnosesData)
  const isLoading = useSelector(getDiagnosesIsLoading)
  const DiagnosisStatus = useSelector(getDiagnosisStatus)
  const searchResults = useSelector(getDiffDxSearchResults)
  const soapId = useSelector(getSoapId)
  const permissions = useSelector(getCRUDByArea(PermissionArea.DIAGNOSE))
  const diagnosesState = useSelector(getDiagnosesState)

  useEffect(() => {
    if (patientId) {
      dispatch(fetchDiagnosesFilters(patientId))
    }
  }, [patientId, soapId])

  useEffect(
    () => () => {
      dispatch(clearSoapDiagnosesFilters())
      dispatch(clearSoapDiagnoses())
    },
    [],
  )

  const [selectedItems, setSelectedItems] = useState<SoapDiagnose[]>([])
  const [diagnoses, setDiagnoses] = useState(diagnosesData)

  useEffect(() => {
    if (diagnosesData) {
      setDiagnoses(diagnosesData)
    }
  }, [diagnosesData])

  useEffect(() => {
    if (!isEmpty(searchResults)) {
      setDiagnoses(pluck('item', searchResults))
    }
  }, [searchResults])

  useEffect(() => {
    setSelectedItems(diagnoses.filter(({ id }) => diagnosesState[id]))
  }, [diagnoses, diagnosesState])

  if (!patientId) {
    return null
  }

  const loadNewItems = ({
    categories,
    entityType,
  }: {
    categories: any[]
    entityType: string
  }) => {
    const categoriesToLoad =
      categories.length > 0
        ? categories
        : filters
            .find((filter) => filter.type === entityType)
            ?.children.map(({ categoryId }) => categoryId) || []
    dispatch(fetchSoapDiagnoses(patientId, categoriesToLoad, entityType))
  }

  const suspectedStatus: string = Utils.findConstantIdByName(
    DiagnosisStatusName.SUSPECTED,
    DiagnosisStatus,
  )

  const createDiagnoseState = ({
    id,
    name,
  }: {
    id: string
    name: string
  }): DiagnosesState => ({
    id,
    name,
    locations: [],
    notes: '',
    statusId: suspectedStatus,
  })

  const updateDiagnoses = (item: any) => {
    dispatch(
      editDifferentialDiagnosesState(
        item.id,
        diagnosesState[item.id] ? undefined : createDiagnoseState(item),
      ),
    )
  }

  const onCheckItem = (item: any) => {
    setSelectedItems((prevOrderedItems) => [...prevOrderedItems, item])
    updateDiagnoses(item)
  }

  const onUncheckItem = (item: any) => {
    setSelectedItems((prevOrderedItems) =>
      prevOrderedItems.filter(({ id }) => item.id !== id),
    )
    updateDiagnoses(item)
  }

  const isChecked = (item: any) =>
    item.items
      ? item.items.length > 0 && item.items.every(isChecked)
      : defaultIsCheckedItem(item, selectedItems)

  const onResetFilter = () => {
    setDiagnoses([])
  }

  const getDiagnoseWithField = (diagnose: SoapDiagnose) =>
    diagnosesState[diagnose.id]
      ? { ...diagnosesState[diagnose.id], locations: diagnose.locations }
      : diagnose

  const diagnosesWithFields = diagnosesData.map(getDiagnoseWithField)
  const searchResultsWithFields = searchResults.map((result) => ({
    ...result,
    item: getDiagnoseWithField(result.item),
  }))

  return (
    <ListWithFilters
      showResetButton
      DetailsComponent={DiffDxExpandedDetails}
      ListItemProps={{
        showListItemActions: true,
      }}
      classes={{
        container: classes.listWithFiltersContainer,
      }}
      clearItems={() => dispatch(clearSoapDiagnoses())}
      editDisabled={editDisabled || !permissions.update}
      filters={filters as any}
      isCheckedItem={isChecked}
      isLoading={isLoading}
      items={diagnosesWithFields}
      loadNewItems={loadNewItems}
      ref={listRef as any}
      searchItems={({ searchTerm }) =>
        dispatch(searchDiagnoses(searchTerm, patientId))
      }
      searchPlaceholder={t('Search:DIFFERENTIAL_DIAGNOSES')}
      searchResults={searchResultsWithFields}
      setEnableBackendFiltering={() => {}}
      onCheckItem={onCheckItem}
      onResetFilter={onResetFilter}
      onUncheckItem={onUncheckItem}
    />
  )
}

export default DifferentialDiagnoses
