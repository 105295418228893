import { StaticFinding } from '~/types/entities/soap'

interface FindingResult {
  finding: StaticFinding
  path: string[]
}

export const containsFinding = (
  child: StaticFinding,
  findingId: string,
): FindingResult | null => {
  const subChild = child?.children?.find((thisChild) =>
    Boolean(containsFinding(thisChild, findingId)),
  )
  if (child?.id === findingId && !subChild) {
    return { path: [findingId], finding: child }
  }
  if (subChild) {
    const result = containsFinding(subChild, findingId)
    if (result) {
      const { path, finding } = result
      return { path: [child.id].concat(path), finding }
    }
  }
  return null
}
