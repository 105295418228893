import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  AlertIconType,
  BasePuiDialogProps,
  CustomFieldValidatorState,
  LanguageUtils,
  PuiAlert,
  PuiTextField,
  useFields,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'

import EnumRadioGroup from '~/components/common/inputs/EnumRadioGroup'
import FeatureToggle from '~/constants/featureToggle'
import {
  getFeatureToggle,
  getInternalCancellationReasons,
} from '~/store/reducers/constants'

import { AppointmentDepositAlert } from './AppointmentDepositAlert'
import AppointmentCancellationWaiveFeeAlert from './reminders/AppointmentCancellationWaiveFeeAlert'

const validateOtherField = (
  otherReasonId: string,
  {
    state: { appointmentCancellationReason, otherReason },
  }: CustomFieldValidatorState,
) => {
  if (appointmentCancellationReason === otherReasonId)
    return Validators.notEmptyFormField(otherReason)

  return true
}

const StyledAlert = styled(PuiAlert)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 500,
    maxWidth: 500,
    padding: theme.spacing(1),
  },
}))

export interface AppointmentCancellationReasonDialogProps
  extends BasePuiDialogProps {
  appointmentWithin24Hours?: boolean
  onCancel?: () => void
  onProceed: (
    appointmentCancellationReasonId: string,
    appointmentCancellationReasonName: string,
    waiveLateCancellationFee: boolean,
  ) => void
  showDepositAlert?: boolean
  showNoShowCancellationPenaltyAlert?: boolean
}

export const AppointmentCancellationReasonDialog = ({
  open,
  onProceed,
  onClose,
  onCancel,
  showDepositAlert = false,
  showNoShowCancellationPenaltyAlert = false,
  appointmentWithin24Hours,
  ...rest
}: AppointmentCancellationReasonDialogProps) => {
  const { t } = useTranslation(['Dialogs', 'Common'])
  const internalCancellationReasons = useSelector(
    getInternalCancellationReasons,
  )

  const noShowPenaltyCardOnFileAlertEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.NO_SHOW_CANCELLATION_PENALTY_CARD_ON_FILE_ALERT,
    ),
  )

  const otherReasonId = Utils.findConstantIdByName(
    'Other',
    internalCancellationReasons,
  )

  const { fields, validate } = useFields(
    [
      {
        name: 'appointmentCancellationReason',
        validators: ['required'],
        type: 'select',
      },
      {
        name: 'otherReason',
        validators: [
          {
            validator: (state) => validateOtherField(otherReasonId, state),
            validatorName: 'required',
          },
        ],
      },
      {
        name: 'waiveLateCancellationFee',
        initialValue: Boolean(noShowPenaltyCardOnFileAlertEnabled),
      },
    ],
    false,
  )

  const {
    appointmentCancellationReason,
    otherReason,
    waiveLateCancellationFee,
  } = fields

  const handleProceed = () => {
    if (!validate()) return

    const reasonName =
      otherReason.value ||
      LanguageUtils.getConstantTranslatedName(
        appointmentCancellationReason.value,
        internalCancellationReasons,
      )

    onProceed(
      appointmentCancellationReason.value,
      reasonName,
      waiveLateCancellationFee.value,
    )
    if (!onClose) return
    onClose()
  }

  const handleCancel = () => {
    onCancel?.()
    onClose?.()
  }

  return (
    <StyledAlert
      aria-labelledby="appointment-cancellation-reason-dialog"
      cancelButtonText={t('Common:GO_BACK')}
      content={
        <>
          <EnumRadioGroup
            Constant={internalCancellationReasons}
            field={appointmentCancellationReason}
          />
          {appointmentCancellationReason.value === otherReasonId && (
            <Box pl={4} width="100%">
              <PuiTextField
                field={otherReason}
                inputProps={{ maxLength: 1500 }}
                label={t(
                  'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_FOR_CANCELLATION',
                )}
              />
            </Box>
          )}
          {showDepositAlert && (
            <AppointmentDepositAlert
              appointmentWithin24Hours={appointmentWithin24Hours}
            />
          )}
          {showNoShowCancellationPenaltyAlert && (
            <AppointmentCancellationWaiveFeeAlert
              field={waiveLateCancellationFee}
            />
          )}
        </>
      }
      iconType={AlertIconType.WARN}
      message={t(
        'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.WHY_IS_THE_APPOINTMENT_BEING_CANCELED',
      )}
      okButtonText={t(
        'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.CANCEL_APPOINTMENT',
      )}
      open={open}
      onCancel={handleCancel}
      onClose={handleCancel}
      onOk={handleProceed}
      {...rest}
    />
  )
}
