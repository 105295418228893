import {
  BaseUser,
  DocumentElement,
  NamedEntity,
  Nil,
  User,
} from '@pbt/pbt-ui-components'

import {
  SoapTemplate as SoapTemplateType,
  SoapTemplateWidget,
} from '~/api/graphql/generated/types'
import { ProblemType } from '~/constants/problems'
import { BodySystemState } from '~/constants/SOAPStates'

import type { DocumentSource, Modify } from '..'
import { AttachmentOrigin } from './files'
import { Order, OrderFilter } from './orders'

export type Soap = {
  assignedVet?: User | Nil
  assignedVetTech?: User | Nil
  creationDate: string
  date: string | Nil
  finalized: boolean
  finalizedBy?: BaseUser
  finalizedDate?: string
  id: string
  primaryComplaint: string | Nil
}

export type SoapStaffInfoList = {
  doctors: string[]
  techs: string[]
}

export type SoapStaffInfoSingle = {
  doctorId: string
  techId: string
}

export type SoapStaffInfo = SoapStaffInfoList & SoapStaffInfoSingle

export type SoapAppointment = {
  appointmentId?: string
  endDate?: string
  startDate?: string
  typeName: string
}

export type SoapData = {
  appointment: SoapAppointment
  client: string
  diagnosisData: {
    diagnoses: DiagnosesStaticList
    diagnosisLogs?: Record<string, DiagnosesState>
  }
  files?: SoapFile[]
  findingLogs?: FindingState[]
  findingLogsContainer?: {
    crc: number
    findingLogs: FindingState[]
  }
  findings: FindingsStaticList
  invoice: string
  orderFilters: OrderFilter[]
  orders?: Order[]
  ordersLocked: boolean
  patient: string
  petParents?: string[]
  soapData: {
    businessId: string
    id: string
    soap: {
      addendums?: string[]
      assignedSpace?: string
      assignedVetId?: string
      assignedVetTechId?: string
      dischargeNotes?: string
      dischargeNotesCrc: number | null
      finalized: boolean
      finalizedBy: User | null
      finalizedDate: string
      medicalNotes?: string
      medicalNotesCrc: number | null
      primaryComplaint: string
      soapFinalizedLogs?: SoapFinalizedLog[]
    }
  }
  vetTechs: string[]
  veterinarians: string[]
  vitals: string[]
  vitalsCount: number
}

export type SoapFile = AttachmentOrigin & {
  author?: User
  businessId?: string
  creationDate?: string
  dateSigned?: string | null
  description?: string
  file?: {
    body?: string
  }
  id: string
  includeInKiosk?: boolean
  required?: boolean
  sign?: boolean
  signer?: string | null
  soapId?: string | Nil
  typeId?: string
}

export enum FormStatus {
  DUE = 'DUE',
  COMPLETE = 'COMPLETE',
}

export type SoapForm = SoapFile & {
  clientId?: string
  creatorId?: string
  deleted?: boolean
  displayLocationIds?: string[]
  documentElements?: DocumentElement[]
  documentId?: string
  documentInstanceToSource?: {
    documentInstanceId: string
    source: DocumentSource
    sourceId: string
  }[]
  expiryDate?: string
  modificationDate?: string
  modifierId?: string
  patientId?: string
  pdfUrl?: string
  status: FormStatus
}

export type FindingDimension = {
  dimension: string
  value: string
}

export type FindingLocation = {
  dimensions: Record<string, FindingDimension>
  id?: string
  name?: string
  notes?: string
}

export type DiagnoseLocation = {
  id: string
  notes?: string
  statusId?: string
}

export type DiagnosesState = {
  id: string
  locations: DiagnoseLocation[]
  name: string
  notes: string
  statusId: string
}

export type DiagnosesStateWithSimpleLocations = Omit<
  DiagnosesState,
  'locations'
> & {
  locations: string[]
}

export type BaseFinding = NamedEntity & {
  fullName: string
  fullNameTranslation?: string
}

export type StaticFinding = BaseFinding & {
  children: StaticFinding[] | null
  dimensions: Record<string, string> | string[] | null
  locations: Record<string, FindingLocation> | string[] | null
}

export type FindingState = BaseFinding & {
  findings?: Record<string, StaticFinding>
  notes: string
  state: BodySystemState
}

export type ApiFindingLog = Omit<FindingState, 'findings'> & {
  findings: StaticFinding[] | []
}

export type ApiFindingsContainerData = {
  crc: number
  findingLogs: ApiFindingLog[]
}

export type FindingsStaticList = StaticFinding[]

export type StaticDiagnose = {
  children: StaticDiagnose[]
  id: string
  locations: string[]
  name: string
}

export type DiagnosesStaticList = StaticDiagnose[]

export type SoapNotesValidationError = {
  fieldName: string
  lastAuthor: User
  lastEditedDate: string
  lastText: string
  newText: string
}

export type SoapFinalizedLog = {
  actionName: string
  finalizedBy: User
  finalizedDate: string
  id: string
}

export type ProblemEnumReference = {
  id: string
  order: number
  parentEnumId?: string | Nil
}

export type ProblemLogEnumReference = {
  enumId: string
  enumValueId: string
  id: string
  notes: string | Nil
  parentEnumValueId: string | Nil
  problemLogId: string
}

export type Problem = Modify<NamedEntity, { id: string | null }> & {
  bodySystemId: string
  breadcrumb: string | null
  breadcrumbTranslation: string | null
  children: string[]
  chipOrder: null | number
  enums: ProblemEnumReference[] | Nil
  isHidden?: boolean
  key: string
  level: number
  logKey: string | null
  onlDisabled: boolean
  primaryId: string | Nil
  removed: boolean
  secondaryId: string | Nil
  status?: string
  tertiaryId: string | Nil
  type: ProblemType | Nil
}

export type ProblemTreeNode = Omit<Problem, 'children' | 'key' | 'logKey'> & {
  children: ProblemTreeNode[] | Nil
}

type ProblemLogPerson = Pick<User, 'id' | 'firstName' | 'lastName'>

export type ProblemLogEnumValueEntity = {
  creationDate: string
  creatorId: string
  enumId: string
  enumValueId: string
  id: string
  modificationDate: string
  modifierId: string
  notes: string | Nil
  parentEnumValueId: string | Nil
  problemLogId: string
}

export type ProblemLogEnumValueContainer = {
  crc: number
  entity: ProblemLogEnumValueEntity
}

export type ProblemLogProblemEntity = {
  bodySystemLogId: string
  createdBy: ProblemLogPerson
  creationDate: string
  enumLogs: ProblemLogEnumValueContainer[]
  id: string
  isCopied: boolean
  modificationDate: string
  modifiedBy: ProblemLogPerson
  notes: string | Nil
  problemId: string
  stateId: string
}

export type ProblemLogProblemContainer = {
  crc: number
  entity: ProblemLogProblemEntity
  key: string
}

export type ProblemLogBodySystemEntity = {
  bodySystemId: string
  createdBy: ProblemLogPerson
  creationDate: string
  id: string
  isCopied: boolean
  modificationDate: string
  modifiedBy: ProblemLogPerson
  notes: string | Nil
  problemLogs: string[]
  soapId: string
  stateId: string
}

export type ProblemLogBodySystemContainer = {
  crc: number
  entity: ProblemLogBodySystemEntity
  key: string
}

export type ProblemLog = {
  crc: string
  entity: string[]
}

export type ProblemLogHistory = {
  date: string
  doctor: ProblemLogPerson | Nil
  enumLogs: ProblemLogEnumReference[]
  notes: string | Nil
  stateId: string
}

export type ProblemEnum = NamedEntity & {
  values: ProblemEnumValue[]
}

export type ProblemEnumValue = NamedEntity & {
  order: number
}

export type ProblemEnumLogInfo = {
  enumId: string
  enumProblem: ProblemEnum
  enumProblemValuesMap: Record<string, ProblemEnumValue>
}

export type AddNewProblemLogPayload = {
  notes: string | Nil
  problemId: string
  stateId: string
}

export type UpdateProblemLogPayload = AddNewProblemLogPayload & {
  bodySystemLogId: string
  crc: number
  id: string
}

export type AddNewProblemBodySystemLogPayload = {
  bodySystemId: string
  notes: string | Nil
  problemLogs: AddNewProblemLogPayload[]
  stateId: string
}

export type UpdateProblemBodySystemLogPayload = {
  bodySystemId: string
  crc: number
  id: string
  notes: string | Nil
  problemLogs?: AddNewProblemLogPayload[]
  removeExistingProblemLogs?: boolean
  soapId: string
  stateId: string
}

export type AddProblemLogEnumValuePayload = {
  enumId: string
  enumValueId: string
  notes: string | Nil
  parentEnumValueId: string | Nil
  problemLogId: string
}

export type UpdateProblemLogEnumValuePayload = {
  crc: number
  enumId: string
  enumValueId: string
  id: string
  notes: string | Nil
  parentEnumValueId: string | Nil
  problemLogId: string
}

export type CreateSoapFileInput = {
  dateSigned?: string
  extension: string
  fileUrl: string
  includeInKiosk: boolean
  name: string
  originalFileName: string
  required: boolean
  signer?: string
  typeId: string
}

export type CreateSoapFileGroupInput = {
  authorId: string
  businessId: string
  date: string
  description: string
  diagnosedIn?: string
  patientId: string
  soapFiles: CreateSoapFileInput[]
  soapId: string
  title?: string
}

export const ProblemStates = {
  SUSPECTED: 'Suspected',
  CONFIRMED: 'Confirmed',
  RULED_OUT: 'Ruled Out',
  CHRONIC: 'Chronic',
  RESOLVED: 'Resolved',
  REMOVED: 'Removed',
  ADDED: 'Added',
  DEFAULT: 'DEFAULT',
}

export type SoapTemplateTab = {
  id: string
  label: string
  name: string
  order: number
  tabElement?: React.JSX.Element
  url: string
  widgets: SoapTemplateWidget[]
}

export enum SoapWidgetName {
  ATTACHMENTS_WIDGET = 'Attachments',
  CHARGES_WIDGET = 'Charges',
  CLIENT_SURVEY = 'Client survey',
  CONSENT_FORMS = 'Forms',
  ESTIMATE_WIDGET = 'Estimates',
  FINALIZE_ORDERS = 'Finalize',
  MARKETPLACE_WIDGET = 'Marketplace',
  NOTES_WIDGET = 'Notes',
  PROBLEMS_WIDGET = 'Problems',
  QUESTIONS_WIDGET = 'Questions',
  REASON_FOR_VISIT_WIDGET = 'Reason for visit',
  REMINDERS_WIDGET = 'Reminders',
  TODO_WIDGET = 'To do',
  VITALS_WIDGET = 'Vitals',
}

export type SoapTemplate = Modify<SoapTemplateType, { tabs: SoapTemplateTab[] }>
