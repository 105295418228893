import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AlertIconType,
  LanguageUtils,
  Nil,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { AddNote } from '@pbt/pbt-ui-components/src/icons'

import ThreeStepsButton from '~/components/common/inputs/ThreeStepsButton'
import ThreeStepsToggle from '~/components/common/inputs/ThreeStepsToggle'
import DialogNames from '~/constants/DialogNames'
import { BodySystemState } from '~/constants/SOAPStates'
import {
  editFindingsState,
  fetchSoapFindingsContainer,
  updateFindingToExpand,
} from '~/store/actions/soap'
import {
  getFindingsState,
  getFindingsStaticList,
  getFindingToExpand,
  getIsSaving,
  getSoapBusinessId,
  getSoapId,
} from '~/store/reducers/soap'
import { FindingLocation, StaticFinding } from '~/types'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import SoapSearch from '../SoapSearch'
// @ts-ignore
import { containsFinding } from '../utils/examinationUtils'
// @ts-ignore
import FindingsView from './FindingsView'
import SubCategoriesView from './SubCategoriesView'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      minWidth: 975,
    },
    column: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tabBorder,
      borderRadius: 2,
      width: 320,
      minWidth: 320,
    },
    columnItem: {
      height: 48,
      position: 'relative',
      cursor: 'pointer',
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    hidePointer: {
      cursor: 'auto',
    },
    categoryTitle: {
      pointerEvents: 'none',
    },
    categoryWNL: {
      opacity: 0.5,
    },
    selectedCategory: {
      position: 'absolute',
      left: -2,
      top: -2,
      border: '1px solid #E48736',
      borderRadius: 2,
      borderRight: 'none',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      pointerEvents: 'none',
    },
    selectedCategoryInner: {
      width: 337,
      height: 49,
      backgroundColor: 'transparent',
      border: '1px solid #FFFFFF',
      borderRightWidth: 18,
    },
    activeColumn: {
      border: '1px solid #E48736',
      marginLeft: theme.spacing(2),
      boxShadow: '-2px 2px 4px 0 rgba(0,0,0,0.10)',
    },
    noteIconButton: {
      padding: theme.spacing(0.5),
      marginLeft: 'auto',
    },
  }),
  { name: 'ExaminationSelect' },
)

interface ExaminationSelectProps {
  confirmCategoryDiscard: (
    categories: string[],
    callback: (confirmed: boolean) => void,
  ) => void
  confirmFindingDiscard: (
    name: string,
    callback: (confirmed: boolean) => void,
  ) => void
  editDisabled?: boolean
}

const ExaminationSelect = ({
  confirmCategoryDiscard,
  confirmFindingDiscard,
  editDisabled,
}: ExaminationSelectProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Abbreviations', 'Common', 'Soap'])

  const findingsStaticList = useSelector(getFindingsStaticList)
  const findingsState = useSelector(getFindingsState)
  const findingPath = useSelector(getFindingToExpand)
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)

  const [selectedCategory, setSelectedCategory] = useState<StaticFinding>()
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<StaticFinding>()
  const [secondColumnHeight, setSecondColumnHeight] = useState(0)
  const [thirdColumnHeight, setThirdColumnHeight] = useState(0)
  const [thirdColumnTop, setThirdColumnTop] = useState(0)
  const [expansionState, setExpansionState] = useState({})
  const [checkboxRefs, setCheckboxRefs] = useState<
    Record<string, React.RefObject<HTMLDivElement>>
  >({})
  const [findingToExpand, setFindingToExpand] = useState<StaticFinding>()
  const [hasUnsavedContent, setHasUnsavedContent] = useState(false)

  const firstColumnRef = useRef<HTMLDivElement>(null)
  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)
  const [openNotesDialog] = useDialog(DialogNames.NOTES)

  const closeSubCategoryAndClearUnsaved = () => {
    closeAlert()
    setHasUnsavedContent(false)
    setSelectedSubCategory(undefined)
    setThirdColumnHeight(0)
    setThirdColumnTop(0)
  }

  const tryCloseSubcategoryAndClearUnsaved = (handleProceed?: () => void) => {
    if (hasUnsavedContent) {
      openAlert({
        cancelButtonText: t('Common:LEAVE_ANYWAY_ACTION'),
        iconType: AlertIconType.WARN,
        message: t('Soap:EXAMINATION_SELECT.ALERT_MESSAGE'),
        okButtonText: t('Common:GO_BACK'),
        onCancel: () => {
          closeSubCategoryAndClearUnsaved()
          handleProceed?.()
        },
        onOk: closeAlert,
      })
    }

    closeSubCategoryAndClearUnsaved()
    handleProceed?.()
  }

  useEffect(() => {
    if (
      !findingsState ||
      !findingsStaticList.length ||
      !selectedCategory ||
      !selectedSubCategory
    ) {
      return
    }
    if (
      !R.path(
        [selectedCategory.id, 'findings', selectedSubCategory.id],
        findingsState,
      )
    ) {
      tryCloseSubcategoryAndClearUnsaved()
    }
  }, [findingsState, selectedCategory, selectedSubCategory])

  const getBodySystemState = (id: string) =>
    findingsState[id] ? findingsState[id].state : BodySystemState.NC

  const updateBodySystemState = (categoryId: string, state: string | Nil) => {
    dispatch(
      editFindingsState({
        bodySystem: Utils.findById(categoryId, findingsStaticList),
        state,
      }),
    )
  }

  const openSubCategory = (
    nodeRef: React.RefObject<HTMLDivElement>,
    subCategory?: StaticFinding,
  ) => {
    tryCloseSubcategoryAndClearUnsaved(() => {
      const LABEL_HEIGHT = 37
      const PADDING = 8
      const BORDER = 1
      const LOCATION_HEIGHT = 32
      const DIMENSION_HEIGHT = 52
      const BRIDGE_HEIGHT = 32
      const NOTES_HEIGHT = 77

      const locationsCount =
        subCategory?.locations && Array.isArray(subCategory.locations)
          ? subCategory.locations.length
          : 0
      const dimensionsCount =
        subCategory?.dimensions && Array.isArray(subCategory.dimensions)
          ? subCategory.dimensions.length
          : 0

      const height =
        locationsCount > 0
          ? 2 * (BORDER + PADDING) +
            LABEL_HEIGHT +
            LOCATION_HEIGHT * locationsCount
          : 2 * (BORDER + PADDING) +
            DIMENSION_HEIGHT * dimensionsCount +
            NOTES_HEIGHT

      const firstColumn = firstColumnRef.current
      const subCategoryElem = nodeRef.current
      const top = firstColumn?.getBoundingClientRect().top || 0

      const bottom = subCategoryElem?.getBoundingClientRect().top || 0

      const spaceTop = bottom - top - (height - BRIDGE_HEIGHT) / 2

      setThirdColumnHeight(height)
      setThirdColumnTop(Math.max(spaceTop, 0))
      setSelectedSubCategory(subCategory)
    })
  }

  const openCategory = (index: number) => {
    if (
      selectedCategory &&
      selectedCategory.id === findingsStaticList[index].id
    ) {
      return
    }

    tryCloseSubcategoryAndClearUnsaved(() => {
      const firstColumn = firstColumnRef.current
      const categoryElem = firstColumn?.children[index + 1]
      const top = firstColumn?.getBoundingClientRect().y || 0
      const bottom =
        (categoryElem?.getBoundingClientRect().y || 0) +
        (categoryElem?.getBoundingClientRect().height || 0)
      const height = bottom - top + 1

      setSelectedCategory(findingsStaticList[index])
      setSecondColumnHeight(height)
    })
  }

  const closeCategory = () => {
    tryCloseSubcategoryAndClearUnsaved(() => {
      setSelectedCategory(undefined)
      setSecondColumnHeight(0)
    })
  }

  const handleAllExaminationStateChange = (state: BodySystemState) => {
    findingsStaticList.forEach(({ id }) => {
      // We don't want to switch ONL to WNL
      if (
        state === BodySystemState.ONL ||
        !findingsState[id] ||
        findingsState[id].state !== BodySystemState.ONL ||
        (findingsState[id].state === BodySystemState.ONL &&
          R.isEmpty(findingsState[id].findings))
      ) {
        updateBodySystemState(id, state)
      }
    })

    if (state === BodySystemState.ONL && findingsStaticList.length > 0) {
      openCategory(0)
    } else {
      closeCategory()
    }
  }

  const onExpandChange = (pathList: string[]) => {
    tryCloseSubcategoryAndClearUnsaved(() => {
      if (R.path(pathList)(expansionState)) {
        setExpansionState(R.assocPath(pathList, undefined, expansionState))
      } else {
        setExpansionState(R.assocPath(pathList, {}, expansionState))
      }
    })
  }

  const onSelectChange = (
    bodySystemId: string,
    finding: StaticFinding,
    nodeRef: React.RefObject<HTMLDivElement>,
  ) => {
    const bodySystem = Utils.findById(bodySystemId, findingsStaticList)
    const { findings = {} } = findingsState[bodySystemId] || {}
    if (!findings[finding.id] && !editDisabled) {
      dispatch(
        editFindingsState({ bodySystem, finding, state: BodySystemState.ONL }),
      )
    }
    openSubCategory(nodeRef, finding)
  }

  const onCheckChange = (
    bodySystemId: string,
    finding: StaticFinding,
    nodeRef: React.RefObject<HTMLDivElement>,
    newState: boolean,
  ) => {
    const bodySystem = Utils.findById(bodySystemId, findingsStaticList)

    if (newState) {
      if (!editDisabled) {
        dispatch(
          editFindingsState({
            bodySystem,
            finding,
            state: BodySystemState.ONL,
          }),
        )
      }

      openSubCategory(nodeRef, finding)
    } else {
      confirmFindingDiscard(finding?.name, (confirmed) => {
        if (confirmed) {
          tryCloseSubcategoryAndClearUnsaved(() => {
            if (!editDisabled) {
              dispatch(
                editFindingsState({
                  bodySystem,
                  finding,
                  state: BodySystemState.ONL,
                  uncheckFinding: true,
                }),
              )
            }
          })
        }
      })
    }
  }

  const getUpdatedDimensions = (
    dimensionId: string,
    dimensionValue: string,
    location: string,
    locations: Record<string, FindingLocation>,
  ) => {
    if (dimensionId) {
      const dimensions: FindingLocation['dimensions'] =
        R.path([location, 'dimensions'], locations) || {}
      if (dimensionValue === '') {
        return R.dissoc(dimensionId, dimensions)
      }
      return {
        ...dimensions,
        [dimensionId]: {
          dimension: dimensionId,
          value: dimensionValue,
        },
      }
    }
    return R.path([location, 'dimensions'], locations) || {}
  }

  const handleLocationChange = ({
    location: locationId,
    locationName,
    dimension,
    dimensionValue,
    uncheckLocation,
  }: {
    dimension: string
    dimensionValue: string
    location: string
    locationName: string
    uncheckLocation?: boolean
  }) => {
    if (editDisabled) {
      return
    }

    const updateLocation = () => {
      const locations: Record<string, FindingLocation> =
        selectedCategory?.id && selectedSubCategory?.id
          ? R.path(
              [
                selectedCategory.id,
                'findings',
                selectedSubCategory.id,
                'locations',
              ],
              findingsState,
            ) || {}
          : {}

      if (selectedCategory) {
        dispatch(
          editFindingsState({
            bodySystem: selectedCategory,
            finding: selectedSubCategory,
            state: BodySystemState.ONL,
            locations: uncheckLocation
              ? R.dissoc(locationId, locations)
              : {
                  ...locations,
                  [locationId]: {
                    ...locations[locationId],
                    id: locationId,
                    dimensions: getUpdatedDimensions(
                      dimension,
                      dimensionValue,
                      locationId,
                      locations,
                    ),
                  },
                },
          }),
        )
      }
    }

    if (!uncheckLocation) {
      updateLocation()
    } else {
      confirmFindingDiscard(`location: ${locationName}`, (confirmed) => {
        if (confirmed) {
          updateLocation()
        }
      })
    }
  }

  const handleLocationNotesChange = () => {
    setHasUnsavedContent(true)
  }

  const handleLocationNotesSave = (
    locationIdProp: string | Nil,
    notes: string,
  ) => {
    if (!editDisabled) {
      const locationId = locationIdProp || ''
      setHasUnsavedContent(false)
      const locations: Record<string, FindingLocation> =
        selectedCategory?.id && selectedSubCategory?.id
          ? R.path(
              [
                selectedCategory.id,
                'findings',
                selectedSubCategory.id,
                'locations',
              ],
              findingsState,
            ) || {}
          : {}

      if (selectedCategory) {
        dispatch(
          editFindingsState({
            bodySystem: selectedCategory,
            finding: selectedSubCategory,
            state: BodySystemState.ONL,
            locations: {
              ...locations,
              [locationId]: {
                ...locations[locationId],
                notes,
              },
            },
          }),
        )
      }
    }
  }

  const handleNavigation = (path?: { id: string }[]) => {
    if (!path || path.length === 0) {
      return
    }

    const category = path[0]
    const categoryIndex = findingsStaticList.findIndex(
      ({ id }) => category.id === id,
    )
    openCategory(categoryIndex)
    updateBodySystemState(category.id, BodySystemState.ONL)
    onExpandChange(path.map(({ id }) => id))
  }

  const getFindingLocations = () => {
    const { findings = {} } =
      (selectedCategory?.id && findingsState[selectedCategory.id]) || {}
    const finding = selectedSubCategory?.id && findings[selectedSubCategory.id]
    return finding && finding.locations
  }

  const onRootClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      closeCategory()
    }
  }

  useEffectExceptOnMount(() => {
    if (findingPath) {
      const { categoryId, findingId } = findingPath
      const category: StaticFinding = Utils.findById(
        categoryId,
        findingsStaticList,
      )
      const result = containsFinding(category, findingId)
      if (result) {
        const { path, finding } = result
        handleNavigation(path.map((id: string) => ({ id })))
        setFindingToExpand(finding)
      }
      dispatch(updateFindingToExpand(null))
    }
  }, [findingPath])

  useEffect(() => {
    const ref = findingToExpand?.id && checkboxRefs[findingToExpand.id]
    if (typeof ref === 'object' && ref?.current) {
      openSubCategory(ref, findingToExpand)
      setFindingToExpand(undefined)
    }
  }, [findingToExpand, checkboxRefs])

  useEffect(() => {
    if (soapId) {
      dispatch(fetchSoapFindingsContainer(soapId, soapBusinessId))
    }
  }, [soapId])

  const setCheckboxRef = (id: string, ref: React.RefObject<HTMLDivElement>) => {
    setCheckboxRefs((oldRefs) => ({ ...oldRefs, [id]: ref }))
  }

  const handleBodySystemNotesChange = () => {
    setHasUnsavedContent(true)
  }

  const handleBodySystemNotesSave = (
    notes: string,
    category?: StaticFinding,
  ) => {
    if (!editDisabled) {
      setHasUnsavedContent(false)
      if (category) {
        dispatch(
          editFindingsState({
            bodySystem: category,
            state:
              (category?.id && findingsState?.[category.id]?.state) ||
              BodySystemState.NC,
            notes: notes || '',
          }),
        )
      }
    }
  }

  const handleShowNoteDialog = (
    bodySystemId: string,
    bodySystemName: string,
  ) => {
    const category = R.find(R.propEq('id', bodySystemId), findingsStaticList)

    openNotesDialog({
      name: bodySystemName,
      notes: findingsState?.[bodySystemId]?.notes,
      onUpdateNotes: (notes: string) =>
        handleBodySystemNotesSave(notes, category),
      isLoadingSelector: getIsSaving,
    })
  }

  if (Object.keys(findingsStaticList).length === 0) {
    return null
  }

  return (
    <Grid container item direction="column">
      <Grid item mb={2}>
        <SoapSearch
          items={findingsStaticList}
          label={t(
            'Soap:EXAMINATION_SELECT.SEARCH_BODY_SYSTEMS_AND_EXAM_FINDINGS',
          )}
          onNavigate={handleNavigation}
        />
      </Grid>
      <Grid
        container
        alignItems="flex-start"
        className={classes.container}
        wrap="nowrap"
        onClick={onRootClick}
      >
        <Grid
          container
          item
          className={classes.column}
          direction="column"
          ref={firstColumnRef}
        >
          <Grid
            container
            item
            alignItems="center"
            className={classNames(classes.columnItem, classes.hidePointer)}
            p={1}
          >
            <ThreeStepsButton
              disabled={editDisabled}
              noneLabel={t('Abbreviations:COMMON.NOT_CHECKED')}
              offLabel={t('Abbreviations:COMMON.OUTSIDE_NORMAL_LIMITS')}
              onClick={(state) => handleAllExaminationStateChange(state)}
              onLabel={t('Abbreviations:COMMON.WITHIN_NORMAL_LIMITS')}
            />
            <Text strong ml={1} variant="body2">
              {t('Common:APPLY_TO_ALL')}
            </Text>
          </Grid>
          {findingsStaticList.map((finding, index) => {
            const categoryId = finding.id
            const categoryName = LanguageUtils.getTranslatedFieldName(finding)
            return (
              <Grid
                container
                item
                alignItems="center"
                className={classNames(classes.columnItem, {
                  [classes.hidePointer]:
                    getBodySystemState(categoryId) !== BodySystemState.ONL,
                })}
                key={categoryId}
                p={1}
                onClick={() => {
                  if (getBodySystemState(categoryId) === BodySystemState.ONL) {
                    openCategory(index)
                  }
                }}
              >
                <ThreeStepsToggle
                  confirmStateChange={(callback) => {
                    const bodySystemState = findingsState[categoryId] || {}
                    if (
                      Object.keys(bodySystemState.findings || {}).length > 0
                    ) {
                      confirmCategoryDiscard([categoryName], (confirmed) => {
                        callback(confirmed)
                      })
                    } else {
                      callback(true)
                    }
                  }}
                  disabled={editDisabled}
                  noneLabel={t('Abbreviations:COMMON.NOT_CHECKED')}
                  noneValue={BodySystemState.NC}
                  offLabel={t('Abbreviations:COMMON.OUTSIDE_NORMAL_LIMITS')}
                  offValue={BodySystemState.ONL}
                  value={getBodySystemState(categoryId)}
                  onLabel={t('Abbreviations:COMMON.WITHIN_NORMAL_LIMITS')}
                  onStateChange={(bodySystemState, fromEvent) => {
                    updateBodySystemState(categoryId, bodySystemState)
                    if (fromEvent) {
                      if (bodySystemState === BodySystemState.ONL) {
                        openCategory(index)
                      } else if (
                        selectedCategory &&
                        selectedCategory.id === findingsStaticList[index].id
                      ) {
                        closeCategory()
                      }
                    }
                  }}
                  onValue={BodySystemState.WNL}
                />
                <Text
                  className={classNames(classes.categoryTitle, {
                    [classes.categoryWNL]:
                      findingsState[categoryId] &&
                      findingsState[categoryId].state === BodySystemState.WNL,
                  })}
                  ml={1}
                  variant="body2"
                >
                  {categoryName}
                </Text>
                <IconButton
                  className={classes.noteIconButton}
                  size="large"
                  onClick={() => handleShowNoteDialog(categoryId, categoryName)}
                >
                  <AddNote filled={Boolean(findingsState[categoryId]?.notes)} />
                </IconButton>
                {selectedCategory && selectedCategory.id === categoryId && (
                  <div className={classes.selectedCategory}>
                    <div className={classes.selectedCategoryInner} />
                  </div>
                )}
              </Grid>
            )
          })}
        </Grid>
        {selectedCategory && (
          <Grid
            container
            item
            className={classNames(classes.column, classes.activeColumn)}
            direction="column"
            p={0.5}
            style={{ minHeight: secondColumnHeight }}
          >
            {selectedCategory.children && (
              <SubCategoriesView
                bodySystemId={selectedCategory.id}
                bodySystemName={LanguageUtils.getTranslatedFieldName(
                  selectedCategory,
                )}
                bodySystemNotes={findingsState?.[selectedCategory.id]?.notes}
                expansionState={expansionState}
                findingsState={findingsState}
                items={selectedCategory.children}
                selectedId={
                  selectedSubCategory ? selectedSubCategory.id : undefined
                }
                setRef={setCheckboxRef}
                onBodySystemNotesChange={handleBodySystemNotesChange}
                onBodySystemNotesSave={(notes: string) =>
                  handleBodySystemNotesSave(notes, selectedCategory)
                }
                onCheckChange={onCheckChange}
                onExpandChange={onExpandChange}
                onSelectChange={onSelectChange}
              />
            )}
          </Grid>
        )}
        {selectedSubCategory && (
          <Grid
            container
            item
            className={classNames(classes.column, classes.activeColumn)}
            direction="column"
            p={1}
            style={{
              minHeight: thirdColumnHeight,
              marginTop: thirdColumnTop,
            }}
          >
            <FindingsView
              dimensions={selectedSubCategory.dimensions}
              locations={selectedSubCategory.locations}
              state={getFindingLocations()}
              subCategoryId={selectedSubCategory.id}
              subCategoryName={LanguageUtils.getTranslatedFieldName(
                selectedSubCategory,
              )}
              onChange={handleLocationChange}
              onLocationNotesChange={handleLocationNotesChange}
              onLocationNotesSave={handleLocationNotesSave}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default ExaminationSelect
